angular.module('app.medicosService', [])
    .factory('medicosService', ['$http', '$q', 'appSettings', function ($http, $q, appSettings) {
        return {
            getCategoriasMedico: function () {
                var deferred = $q.defer();
                $http.get(appSettings.restApiServiceBaseUri + 'categoriasMedico').success(function (data) {
                    deferred.resolve(data);
                }).error(function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            crearMedico: function (data) {
                var url = appSettings.restApiServiceBaseUri + 'medicos'
                return $http.post(url, data).then(function (response) {
                    return response;
                });
            },
            listMedicos: function () {
                var deferred = $q.defer();
                $http.get(appSettings.restApiServiceBaseUri + 'medicos').success(function (data) {
                    deferred.resolve(data);
                }).error(function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            getMedico: function (medicoId) {
                var deferred = $q.defer();
                $http.get(appSettings.restApiServiceBaseUri + 'medicos/' + medicoId).success(function (data) {
                    deferred.resolve(data);
                }).error(function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            setModificarMedico: function (data) {
                var url = appSettings.restApiServiceBaseUri + 'medicos/modificar';
                var promise = $http.put(url, data).then(function (response) {
                    return response.status == 204;
                });

                return promise;
            },
            setSolicitarAutorizacion: function (medicoId, mensaje) {
                var url = appSettings.restApiServiceBaseUri + 'medicos/' + medicoId + '/solicitar_autorizacion ';
                var promise = $http.post(url, mensaje).then(function (response) {
                    return response.status == 204;
                });

                return promise;
            },
            darBajaMedico: function (medicoId) {
                var url = appSettings.restApiServiceBaseUri + 'medicos/' + medicoId + '/dar-de-baja';
                var promise = $http.put(url).then(function (response) {
                    return response.status == 204;
                });

                return promise;
            },
            getCitasMedicoPorFecha: function (medicoId, fechaInicio, fechaFin) {
                var url = appSettings.restApiServiceBaseUri + 'medicos/' + medicoId + '/citas/rango_fechas/?fechaInicio=' + fechaInicio + '&fechaFin=' + fechaFin;
                var promise = $http.get(url).then(function (response) {
                    if (response.status == 200)
                        return response.data;
                    return response;
                });

                return promise;
            },
            getConsultorios: function (medicoId) {
                var url = appSettings.restApiServiceBaseUri + 'medicos/' + medicoId + '/consultorios';
                return $http.get(url).then(function (response) {
                    if (response.status == 200)
                        return response.data;
                    return response;
                });
            },
            filtrarListaMedicos: function (filtros) {
                var url = appSettings.restApiServiceBaseUri + 'medicos/filtrar-lista-de-medicos';
                return $http.post(url, filtros).then(function (response) {
                    return response.data;
                })
            }
        }
    }]);
