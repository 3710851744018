angular.module('app.medicamentos', [
  'ui.router',
  'toastr',
  'app.medicamentosService'
])
  
.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.medicamentos', {

          abstract: true,

          url: 'medicamentos',

          params: {
            
          },

          views: {
            '': {
              templateUrl: 'app/medicamentos/medicamentos.tpl.html',
              resolve: {

              },
              controller: ['$scope', '$state', 'toastr',
                function (  $scope,   $state,   toastr) {

                  
                  
                }]
            }
          }
          
        })
        .state( 'index.medicamentos.agregar', {

          url: '/agregar',

          views: {
            '': {
              templateUrl: 'app/medicamentos/medicamentos.add.tpl.html',
              resolve: {
                
              },
              controller: ['$scope', '$state', 'toastr', 'medicamentosService',
                function (  $scope,   $state,   toastr,   medicamentosService) {
                  //Definiciones
                  $scope.medicamento = {};
                  //Funciones
                  $scope.submitForm = function ( isValid ) {
                    
                    medicamentosService.setMedicamento($scope.medicamento).then(function(response) {
                        if(response.status == 201){
                          toastr.success('AVISO: Medicamento creado exitosamente');
                          $scope.medicamento = {};
                        } else {
                          toastr.warning("AVISO: No se ha podido ingresar el Medicamento " + responses);
                        }
                    }
                    , function(error) {
                        toastr.error(error.data.message);
                    });
                  };
                }]
            }
          }
        })
        .state( 'index.medicamentos.listar', {

          url: '/ver',

          views: {
            '': {
              templateUrl: 'app/medicamentos/medicamentos.list.tpl.html',
              resolve: {
                medicamentos: ['medicamentosService',
                  function(medicamentosService) {
                    return medicamentosService.getMedicamentos();
                  }
                ],
              },
              controller: ['$scope', '$state', 'toastr', 'medicamentosService', 'medicamentos',
                function (  $scope,   $state,   toastr,   medicamentosService,   medicamentos) {
                  //Catalogos
                  //Definiciones
                  $scope.vista = 1;
                  $scope.medicamento = {};
                  $scope.medicamentoId = null;
                  //Grids
                  $scope.gridOptionsMedicamentos = angular.copy( $scope.gridOptions );
                  $scope.gridOptionsMedicamentos.columnDefs = [
                  { name: 'medicamentoId', displayName: "Código", enableFiltering: false },
                  { name: 'medicamentoNombre', displayName: "Nombre", enableFiltering: false },
                  { name: 'OPCIONES', enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span><button type="button" class="btn btn-success btn-xs" ng-click="grid.appScope.details(row.entity)" title="Ver"><i class="fa fa-eye"></i></button> <button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.edit(row.entity)" title="Editar"><i class="fa fa-edit"></i></button> <button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.delete(row.entity)" title="Eliminar"><i class="fa fa-remove"></i></button></span></div>' },
                  ];
                  $scope.gridOptionsMedicamentos.data = medicamentos;
                  //Funciones
                  $scope.verList=function(){
                    $scope.vista = 1;
                  };

                  $scope.details = function (row) {
                    $scope.vista = 2;
                    $scope.estaEditando = false;
                    $scope.rowSeleccionado = row;
                    medicamentosService.getMedicamento($scope.rowSeleccionado.medicamentoId).then( function ( result ) {
                      $scope.medicamento = result;
                      $scope.vista = 2;
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  };

                  $scope.delete = function(row) {
                    medicamentosService.eliminarMedicamento(row.medicamentoId).then(function (result) {
                      toastr.success('Medicamento eliminado');
                      var index = $scope.gridOptionsMedicamentos.data.indexOf(row);
                      $scope.gridOptionsMedicamentos.data.splice(index, 1);
                    });
                  }

                  // Funcion para editar
                  $scope.edit = function (row) {
                    $scope.vista = 2;
                    $scope.estaEditando = true;
                    $scope.rowSeleccionado = row;
                    medicamentosService.getMedicamento($scope.rowSeleccionado.medicamentoId).then( function ( result ) {
                      $scope.medicamento = result;
                      $scope.medicamentoId = $scope.medicamento.medicamentoId
                      $scope.vista = 2;
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  };

                  $scope.modificarMedicamento = function (){
                    if($scope.medicamentoId != null){
                      medicamentosService.setModificarMedicamento($scope.medicamentoId, $scope.medicamento).then(function (response) {
                        if (response){
                          toastr.success('Datos modificados con éxito.');
                          $scope.gridOptionsMedicamentos.data = [];
                          medicamentosService.getMedicamentos().then( function ( result ) {
                            $scope.gridOptionsMedicamentos.data = result;
                            $scope.vista = 1;
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        }
                      }, function (error) {
                        if (error.data.message)
                          toastr.error(error.data.message);
                        else
                          toastr.error('Ha ocurrido un error');
                      });
                    }
                  };

                }]
            }
          }
        })
    }
  ]
);