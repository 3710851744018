angular.module('app.reportesService', [
  
])
.factory('reportesService', ['$http', '$q', 'appSettings', function ( $http, $q, appSettings ) {
  return {
  	getCitasReporte: function (data) {
      var promise = $http.post(appSettings.restApiServiceBaseUri + 'citas/reporte', data).then(function (response) {
        return response;
      });
      return promise;
  	},
  	visitasPendientes: function (data) {
  	    return $http.post(appSettings.restApiServiceBaseUri + 'citas/pendientes', data).then(function (response) {
  	        if (response.status == 200)
  	            return response.data;
  	        return response;
  	    });
  	}
  }
}]);