angular.module('app.institucionesService', [])

    .factory('institucionesService', ['$http', '$q', 'appSettings', function ($http, $q, appSettings) {
        return {
            setInstitucion: function (data) {
                var url = appSettings.restApiServiceBaseUri + 'instituciones'
                return $http.post(url,data).then(function (response) {
                    return response;
                });
            },
            listInstituciones: function () {
                var deferred = $q.defer();
                $http.get(appSettings.restApiServiceBaseUri + 'instituciones').success(function (data) {
                    deferred.resolve(data);
                }).error(function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            getInstitucionDetalles: function (institucionId) {
                var url = appSettings.restApiServiceBaseUri + 'instituciones/' + institucionId;
                var promise = $http.get(url).then(function (response) {
                    if (response.status == 200) {
                        return response.data;
                    }

                    return response;
                });

                return promise;
            },
            setModificarInstitucion: function (request) {
                var url = appSettings.restApiServiceBaseUri + 'instituciones/modificar';
                var promise = $http.put(url, request).then(function (response) {
                    return response.status == 204;
                });

                return promise;
            },
            setSolicitarAutorizacion: function (institucionId, mensaje) {
                var url = appSettings.restApiServiceBaseUri + 'instituciones/' + institucionId + '/solicitar_autorizacion ';
                var promise = $http.post(url, mensaje).then(function (response) {
                    return response.status == 204;
                });

                return promise;
            },
            getCitasInstitucionPorFecha: function (institucionId, fechaInicio, fechaFin) {
                var url = appSettings.restApiServiceBaseUri + 'instituciones/' + institucionId + '/citas/rango_fechas/?fechaInicio=' + fechaInicio + '&fechaFin=' + fechaFin;
                var promise = $http.get(url).then(function (response) {
                    if (response.status == 200)
                        return response.data;
                    return response;
                });

                return promise;
            },
            darBaja: function (institucionId) {
                var url = appSettings.restApiServiceBaseUri + 'instituciones/' + institucionId + '/dar-de-baja';
                var promise = $http.put(url).then(function (response) {
                    if (response.status == 200)
                        return response.data;
                    return response;
                });

                return promise;
            },
            getInstitucionesPorUsuario: function (username)
            {
                var url = appSettings.restApiServiceBaseUri + 'instituciones/usuario/' + username;
                var promise = $http.get(url).then(function (response) {
                    if (response.status == 200)
                        return response.data;
                    return response;
                });

                return promise;
            }
        }
    }]);