angular.module('app.medicamentosService', [
  
])

.factory('medicamentosService', ['$http', '$q', 'appSettings', function ( $http, $q, appSettings ) {
  return {
  	getMedicamentos: function() {
      var promise = $http.get(appSettings.restApiServiceBaseUri + 'medicamentos').then(function (response) {
        if (response.status == 200)
          return response.data
      });
      return promise;
    },
    setMedicamento: function (data) {
      var promise = $http.post(appSettings.restApiServiceBaseUri + 'medicamentos', data).then(function (response) {
        return response;
      });
      return promise;
    },
    getMedicamento: function(medicamentoId) {
      var promise = $http.get(appSettings.restApiServiceBaseUri + 'medicamentos/' + medicamentoId).then(function (response) {
        if (response.status == 200)
          return response.data
      });
      return promise;
    },
    setModificarMedicamento: function (medicamentoId, data) {
		var url = appSettings.restApiServiceBaseUri + 'medicamentos/' + medicamentoId + '/modificar';
		var promise = $http.put(url, data).then(function (response) {
			return response.status == 204;
		});
		return promise;
    },
      eliminarMedicamento: function (medicamentoId) {
          var url = appSettings.restApiServiceBaseUri + 'medicamentos/eliminar/' + medicamentoId;
          return $http.put(url).then(function (response) {
              return response;
          });
      }
  }   
}]);