(function () {
    'use strict';

    angular
        .module('app.medicos')
        .controller('DetalleMedicoController', DetalleMedicoController);


    DetalleMedicoController.$inject = ['medico', 'citas', '$state', 'medicosService', '$stateParams', 'catalogosService', 'eliminar', 'ERROR_GENERICO', 'toastr',
    'MEDICO_ELIMINADO', 'DESEA_ELIMINAR_MEDICO', 'NO_HAY_CITAS_RANGO_FECHAS'];

    function DetalleMedicoController(medico, citas, $state, medicosService, $stateParams, catalogosService, eliminar, ERROR_GENERICO, toastr,
                                     MEDICO_ELIMINADO, DESEA_ELIMINAR_MEDICO, NO_HAY_CITAS_RANGO_FECHAS) {

        var vm = this;

        var page = 0;
        var pageSize = 5;
        var medicoId = $stateParams.medicoId;
        vm.estaEditando = false;
        vm.citasMedico = [];
        vm.medico = medico;
        vm.citasMedico = citas.content;
        vm.hayMasCitas = !citas.last;
        vm.vista = 2;

        vm.verMasCitas = verMasCitas;
        vm.obtenerCitas = obtenerCitas;
        vm.verList = verList;
        vm.editar = editar;
        vm.darDeBaja = darDeBaja;
        vm.puedeEliminar = eliminar;

        function verMasCitas() {
            page = page + 1;
            catalogosService.getCitasMedico(medicoId, page, pageSize).then(function (result) {
                result.content.forEach(function (item) {
                    vm.citasMedico.push(item);
                });
                vm.hayMasCitas = !result.last;
            }, function (error) {
                if (error.data.message)
                    toastr.error(error.data.message);
                else
                    toastr.error(ERROR_GENERICO);
            });
        }

        function obtenerCitas() {
            var data = {};
            var fechaInicio = vm.rango.fechaInicio.toLocaleDateString();
            var fechaFin = vm.rango.fechaFin.toLocaleDateString();
            medicosService.getCitasMedicoPorFecha(medicoId, fechaInicio, fechaFin).then(function (response) {

                if (response.length == 0) {
                    toastr.info(NO_HAY_CITAS_RANGO_FECHAS);
                    vm.rango = {};
                }
                vm.citasMedico = response;
                vm.hayMasCitas = false;
                vm.rango = {};
            }, function (error) {
                if (error.data.message)
                    toastr.error(error.data.message);
                else
                    toastr.error(ERROR_GENERICO);
                vm.rango = {};
            });
        }

        function verList() {
            $state.go('index.medicos.listar')
        }

        function editar() {
            $state.go('index.medicos.modificar', {medicoId: medicoId});
        }

        function darDeBaja() {

            swal({
                    title: DESEA_ELIMINAR_MEDICO,
                    text: '',
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    confirmButtonText: "Confirmar",
                    cancelButtonClass: "btn-danger",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    dangerMode: true,
                },
                function () {
                    medicosService.darBajaMedico(medicoId).then(function (response) {
                        toastr.success(MEDICO_ELIMINADO);
                        $state.go('index.medicos.listar')
                    }, function (error) {
                        toastr.error(ERROR_GENERICO);
                    });
                });
        }
    }
})();