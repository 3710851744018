(function () {
    'use strict';

    angular
        .module('app.usuarios')
        .controller('VerUsuariosController', VerUsuariosController);

    VerUsuariosController.$inject = ['usuarios', 'NgTableParams', 'usuariosService', 'ERROR_GENERICO', 'toastr', 'USUARIO_MODIFICADO'];
    
    function VerUsuariosController(usuarios, NgTableParams, usuariosService, ERROR_GENERICO, toastr, USUARIO_MODIFICADO) {

        var vm = this;

        var request = {};
        request.sectoresAgregados = [];
        request.sectoresRemovidos = [];

        vm.tablaUsuarios = new NgTableParams({page: 1, count: 10}, {dataset: usuarios});
        vm.estaEditando = false;
        vm.seleccionarUsuario = seleccionarUsuario;
        vm.editar = editar;
        vm.modificar = modificar;
        vm.eliminarSector = eliminarSector;
        vm.agregarSector = agregarSector;

        function seleccionarUsuario(usuarioId) {
            vm.estaEditando = false;
            vm.idUsuarioSeleccionado = usuarioId;
            usuariosService.obtenerUsuarioPorId(usuarioId).then(function (response) {
                vm.usuario = response;
                vm.sectoresTmp = vm.usuario.sectores;
            }, function (error) {
                toastr.error(ERROR_GENERICO);
            });
        }
        
        function editar() {
            vm.estaEditando = true;
            usuariosService.getSectoresNoAsignados().then(function (response) {
                vm.sectores = response;
            }, function (error) {
                toastr.error(ERROR_GENERICO);
            });

            usuariosService.getRoles().then(function (response) {
                vm.roles = response;
            }, function (error) {
                toastr.error(ERROR_GENERICO);
            });
        }

        function eliminarSector (sector) {
            vm.sectoresTmp = vm.sectoresTmp.filter(function (item) {
                return item.id != sector.id;
            });
            request.sectoresRemovidos.push(sector)
        }

        function modificar() {
            request.usuario = vm.usuario;
            usuariosService.modificarUsuario(request).then(function (response) {
                toastr.success(USUARIO_MODIFICADO);
                request.sectoresRemovidos = [];
                request.sectoresAgregados = [];
            }, function (error) {
                if (error.data.message)
                    toastr.error(error.data.message);
                else
                    toastr.error(ERROR_GENERICO);
            });
        }

        function agregarSector () {
            if (vm.usuario.sector) {
                var exists = vm.sectoresTmp.find(function (item) {
                    return item.id == vm.usuario.sector.id;
                });
                if (!exists) {
                    vm.sectoresTmp.push(vm.usuario.sector);
                    request.sectoresAgregados.push(vm.usuario.sector);
                }
            }
        }
    }
})();