(function () {

    'use strict';

    angular
        .module('app.dashboard')
        .config(config);

    function config($stateProvider) {
        $stateProvider
            .state('index.dashboard', {
                url: 'dashboard',
                abstract: true,
                template: '<ui-view/>'
            })
            .state('index.dashboard.ver', {
                url: '',
                templateUrl: 'app/dashboard/dashboard.tpl.html',
                controller: 'DashboardController',
                controllerAs: 'vm',
                resolve: {
                    auth: auth,
                    ciclos: ciclosService,
                    semanas: semanasService,
                    cicloActual: cicloActualService,
                    usuarios: usuariosService,
                    anios: aniosService
                }
            });
    }

    function aniosService(catalogosService) {
        return catalogosService.getAnios().then(function (response) {
            return response;
        });
    }

    function ciclosService(catalogosService) {
        return catalogosService.getCiclos().then(function (response) {
            return response;
        });
    }

    function semanasService(catalogosService) {
        return catalogosService.getSemanas().then(function (response) {
            return response;
        });
    }

    function cicloActualService(catalogosService) {
        return catalogosService.getCicloActual().then(function (response) {
            if (response.status == 200)
                return response.data;
        }, function (error) {
            return null;
        });
    }

    function usuariosService(catalogosService, authFactory, auth) {
        if (authFactory.estaEnRol('ROLE_ADMIN') || authFactory.estaEnRol('ROLE_SUPERVISOR')) {
            return catalogosService.getUsuarios().then(function (response) {
                return response;
            });
        }
    }

    function auth(authFactory) {
        return authFactory._identidad(true);
    }

})();