(function () {
    'use strict';

    angular
        .module('app.usuarios')
        .config(config);
    
    function config($stateProvider) {
        $stateProvider
            .state('index.usuarios', {
                url: 'usuarios',
                abstract: true,
                template: '<ui-view/>'
            })
            .state('index.usuarios.list', {
                url: '/ver',
                templateUrl: 'app/usuarios/usuarios.ver.tpl.html',
                controller: 'VerUsuariosController',
                controllerAs: 'vm',
                resolve: {
                    usuarios: usuarios
                }
            })
            .state('index.usuarios.agregar', {
                url: '/agregar',
                templateUrl: 'app/usuarios/usuarios.agregar.tpl.html',
                controller: 'AgregarUsuariosController',
                controllerAs: 'vm',
                resolve: {
                    roles: roles,
                    sectores: sectores
                }
            });
    }

    function usuarios(catalogosService) {
        return catalogosService.getUsuarios();
    }

    function roles(usuariosService) {
        return usuariosService.getRoles();
    }

    function sectores(usuariosService) {
        return usuariosService.getSectoresNoAsignados();
    }

})();