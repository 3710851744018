angular.module( 'app.authInterceptorService', [
  'LocalStorageModule'
])

.factory('authInterceptorService', ['$q', '$injector', '$location', 'localStorageService','$httpParamSerializer',
    function ($q, $injector, $location, localStorageService, $httpParamSerializer) {

    var authInterceptor = {};

    authInterceptor.request = function (config) {
        // Agregamos el token solo cuando las peticiones sean al resource server

        var authData = localStorageService.get('loginData');
        if (authData) {

            if (config.url.startsWith('resource/')) {
                config.headers = config.headers || {};
                    authData = verificarToken(authData).then(function (response) {
                    config.headers.Authorization = 'Bearer ' + response.access_token;
                    return config;
                }, function () {
                    // si no puede renovar el token, cierra sesion.
                    localStorageService.remove('loginData');
                    location.href = "#/login";
                    return config;
                });

                return authData;
            }
        }
        
        return config;
    }


        // hace una peticion al oauth server para renovar el token actual
    function renovarToken(refresh_token) {

        var data = {};
        data.grant_type = 'refresh_token';
        var $http = $injector.get('$http');
        data.refresh_token = refresh_token;
        var req = {
            method: 'POST',
            url: appSettings.restOauth,
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=utf-8"
            },
            data: $httpParamSerializer(data)
        };

        var promise = $http(req).then(function (response) {
            // sobreescribe la informacion actual, para usar el nuevo token en request siguientes
            localStorageService.set('loginData', response.data);
            return response.data;
        });

        return promise;
    }

    function verificarToken(authData) {
        var deferred = $q.defer();
        var time = authData.iat;
        var expires_in = new Date(time * 1000);
        expires_in.setSeconds(expires_in.getSeconds() + authData.expires_in);
        var now = new Date();
        if (now > expires_in) {
            return renovarToken(authData.refresh_token).then(function (response) {
                return response;
            });
        } else {
            deferred.resolve(authData);
            return deferred.promise;
        }
    }

  return authInterceptor;
}]);