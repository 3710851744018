(function () {
    'use strict';

    angular
        .module('app.instituciones')
        .controller('DetalleInstitucionController', DetalleInstitucionController);


    DetalleInstitucionController.$inject = ['$state', 'toastr', 'institucionesService', 'catalogosService', 'ngDialog', '$stateParams', 'institucion', 'eliminar',
        'ERROR_GENERICO', 'INSTITUCION_ELIMINADA', 'DESEA_ELIMINAR_INSTITUCION'];

    function DetalleInstitucionController($state, toastr, institucionesService, catalogosService, ngDialog, $stateParams, institucion, eliminar,
                                          ERROR_GENERICO, INSTITUCION_ELIMINADA, DESEA_ELIMINAR_INSTITUCION) {

        var vm = this;

        vm.vista = 2;
        vm.citasInstitucion = [];
        vm.institucion = {};
        vm.rango = {};
        vm.horarios = [{ "horarioNombre": "AM" }, { "horarioNombre": "PM" }];

        vm.verLista = verLista;
        vm.verMasCitas = verMasCitas;
        vm.obtenerCitas = obtenerCitas;
        vm.editar = editar;
        vm.darDeBaja = darDeBaja;
        vm.institucion = institucion;
        vm.puedeEliminar = eliminar;
        vm.estaEditando = false;

        var pageSize = 5; // cantidad de citas por pagina
        var page = 0; // pagina inicial
        var institucionId = $stateParams.institucionId;

       iniciar();

        function iniciar() {

            catalogosService.getCitasInstitucion(institucionId, page, pageSize).then(function (response) {
                vm.citasInstitucion = response.content;
                vm.hayMasCitas = !response.last;
            }, function (error) {
                toastr.error(ERROR_GENERICO);
            });
        }

        // ver mas citas
        function verMasCitas () {
            page = page + 1;
            catalogosService.getCitasInstitucion(institucionId, page, pageSize).then(function (response) {
                response.content.forEach(function (item) {
                    vm.citasInstitucion.push(item);
                });
                vm.hayMasCitas = !response.last;
            }, function (error) {
                if (error.data.message)
                    toastr.error(error.data.message);
                else
                    toastr.error(ERROR_GENERICO);
            });
        }// ver mas citas

        // obtener citas
        function obtenerCitas () {
            var data = {};
            var fechaInicio = vm.rango.fechaInicio.toLocaleDateString();
            var fechaFin = vm.rango.fechaFin.toLocaleDateString();
            institucionesService.getCitasInstitucionPorFecha(institucionId, fechaInicio, fechaFin).then(function (response) {
                if (response.length == 0) {
                    toastr.info('No hay vitas en las fechas especificadas');
                    vm.rango = {};
                }
                vm.citasInstitucion = response;
                vm.hayMasCitas = false;
                vm.rango = {};
            }, function (error) {
                console.log(error);
                if (error.data.message)
                    toastr.error(error.data.message);
                else
                    toastr.error(ERROR_GENERICO);
                vm.rango = {};
            });
        } // obtener citas

        function verLista() {
            $state.go('index.instituciones.listar');
        }

        function editar() {
            $state.go('index.instituciones.modificar', {institucionId : institucionId});
        }
        
        function darDeBaja() {

            swal({
                    title: DESEA_ELIMINAR_INSTITUCION,
                    text: '',
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    confirmButtonText: "Confirmar",
                    cancelButtonClass: "btn-danger",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    dangerMode: true,
                },
                function () {
                    institucionesService.darBaja(institucionId).then(function (response) {
                        toastr.success(INSTITUCION_ELIMINADA);
                        $state.go('index.instituciones.listar')
                    }, function (error) {
                        toastr.error(ERROR_GENERICO);
                    });
                });
        }
    }
})();