(function () {
    'use strict';

    angular.module('app.citas', [
        'ui.router',
        'toastr',
        'app.citasService',
        'app.authService',
        'ngMaterial',
        'ngMessages',
        'LocalStorageModule'
    ]).constant('VISITAS_PROGRAMADAS_EXITOSAMENTE','Visitas programadas exitosamente')
        .constant('ERROR_PROGRAMAR_VISITAS', 'No se han podido programar todas las visitas')
        .constant('ERROR_CATEGORIA_VISITAS', 'Debe seleccionar una categoría de visitas válida')
        .constant('ERROR_HORA_VISITAS', 'Debe seleccionar una hora para la visita')
        .constant('VISITAS_GUARDADAS_EXITOSAMENTE', 'Visitas guardadas exitosamente')
        .constant('NO_HAY_VISITAS_PARA_GUARDAR', 'No hay visitas para guardar');

    angular.module('app.citas')
        .filter('isObject', isObject);

    function isObject() {
        return function(input) {
            return angular.isObject(input);
        }
    }
})();