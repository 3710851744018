(function () {

    'use strict';

    angular
        .module('app.instituciones')
        .config(config);

    function config($stateProvider) {
        $stateProvider
            .state('index.instituciones', {
                url: 'instituciones',
                abstract: true,
                template: '<ui-view/>'
            })
            .state('index.instituciones.agregar', {
                url: '/agregar',
                templateUrl: 'app/instituciones/instituciones.add.tpl.html',
                controller: 'AgregarInstitucionesController',
                controllerAs: 'vm',
                resolve: {
                    tiposInstitucion: tiposInstitucionService,
                    cadenasFarmacia: cadenasFarmaciaService,
                    potenciales: potencialesService,
                    paises: paisesService,
                    semanas: semanasService,
                    giraCapital: giraCapitalService,
                    sectores: sectoresService,
                    dias: diasService
                }
            })
            .state('index.instituciones.listar', {
                url: '/ver',
                templateUrl: 'app/instituciones/instituciones.list.tpl.html',
                controller: 'ListarInstitucionesController',
                controllerAs: 'vm',
                resolve: {
                    auth: autorizar,
                    usuarios: usuariosService,
                    instituciones: institucionesService
                }
            })
            .state('index.instituciones.detalle', {
                url: '/detalle/{institucionId}',
                templateUrl: 'app/instituciones/instituciones.detalle.tpl.html',
                controller: 'DetalleInstitucionController',
                controllerAs: 'vm',
                resolve: {
                    auth: autorizar,
                    institucion: institucion,
                    eliminar: eliminar
                }
            })
            .state('index.instituciones.modificar', {
                url: '/modificar/{institucionId}',
                templateUrl: 'app/instituciones/instituciones.detalle.tpl.html',
                controller: 'ModificarInstitucionController',
                controllerAs: 'vm',
                resolve: {
                    institucion: institucion,
                    potenciales: potencialesService,
                    paises: paisesService,
                    semanas: semanasService,
                    giraCapital: giraCapitalService,
                    sectores: sectoresService,
                    dias: diasService
                }
            })
    }

    function autorizar(authFactory) {
        return authFactory._identidad(true);
    }

    function usuariosService(usuariosService, authFactory, auth) {
        if (authFactory.estaEnRol('ROLE_ADMIN') || authFactory.estaEnRol('ROLE_SUPERVISOR'))
        {
            return usuariosService.getVisitadores();
        }
    }
    function tiposInstitucionService(catalogosService) {
        return catalogosService.getTiposInstitucion();
    }

    function cadenasFarmaciaService(catalogosService) {
        return catalogosService.getCadenasFarmacia();
    }

    function potencialesService(catalogosService) {
        return catalogosService.getPotenciales();
    }

    function paisesService(catalogosService) {
        return catalogosService.getPaises();
    }

    function semanasService(catalogosService) {
        return catalogosService.getSemanas();
    }

    function giraCapitalService(catalogosService) {
        return catalogosService.getGiraCapital().then(function (response) {
            return response.data;
        });
    }

    function sectoresService(catalogosService) {
        return catalogosService.getAllSectores().then(function (result) {
            return result;
        }, function (error) {
            return null;
        });
    }

    function institucionesService(institucionesService) {
        return institucionesService.listInstituciones();
    }

    function institucion($stateParams, institucionesService) {
        var institucionId = $stateParams.institucionId
        return institucionesService.getInstitucionDetalles(institucionId).then(function (response) {
            return response;
        });
    }
    function eliminar(solicitudesService, $stateParams, authFactory, auth) {
        if (authFactory.estaEnRol('ROLE_ADMIN')) {
            return true;
        }
        else {
            return solicitudesService.validarEliminar(crearData($stateParams.institucionId));
        }
    }

    function autorizar(authFactory) {
        return authFactory._identidad(true);
    }

    function crearData(institucionId) {
        var data = {};
        data.medicoId = null;
        data.institucionId = institucionId;
        return data;
    }

    function diasService(catalogosService) {
        return catalogosService.getDias();
    }

})();