(function () {
    'use strict';

    angular
        .module('app.medicos')
        .controller('AgregarMedicosController', AgregarMedicosController);

    AgregarMedicosController.$inject = ['$state', 'toastr', 'medicosService', 'catalogosService', 'especialidades', 'categoriasMedico', 'potenciales',
                'paises', 'zonas', 'semanas', 'dias', 'giraCapital', 'sectores']


    function AgregarMedicosController($state, toastr, medicosService, catalogosService, especialidades, categoriasMedico, potenciales,
                paises, zonas, semanas, dias, giraCapital, sectores) {

        var vm = this;

        vm.especialidades = especialidades;
        vm.categoriasMedico = categoriasMedico;
        vm.potenciales = potenciales;
        vm.paises = paises;
        vm.zonas = zonas;
        vm.semanas = semanas;
        vm.dias = dias;
        vm.sectores = sectores;
        vm.horarios = [{ "horarioNombre": "AM" }, { "horarioNombre": "PM" }];
        vm.giraCapital = giraCapital.data;
        vm.medicoData = {};
        vm.medico = {};
        vm.medico.medicoSector = [];
        vm.medicoSector = {};
        vm.departamentos = [];
        vm.ciudades = [];
        vm.giraCapitalId = null;
        vm.submitted = false;
        vm.getDepartamentos = getDepartamentos;
        vm.getCiudades = getCiudades;
        vm.submitForm = submitForm;
        vm.cancelar = cancelar;

        function cancelar() {
            $state.go('index.home');
        }

       function getDepartamentos(paisId) {
            catalogosService.getDepartamentos(paisId).then(function (result) {
                vm.departamentos = result;
            }, function (error) {
                toastr.error(error);
            });
        };

        function getCiudades(departamentoId) {
            catalogosService.getCiudades(departamentoId).then(function (result) {
                vm.ciudades = result;
            }, function (error) {
                toastr.error(error);
            });
        };

        function submitForm(isValid) {

            if (vm.giraCapitalId == 1) {
                vm.medico.gira = true;
                vm.medico.capital = false;
            } else {
                vm.medico.gira = false;
                vm.medico.capital = true;
            }

            if (!angular.equals(vm.medicoSector, {})) {
                vm.medico.medicoSector.push(vm.medicoSector);
            }

            console.log(vm.medico);

            medicosService.crearMedico(vm.medico).then(function (response) {

                if (response.status == 201) {
                    toastr.success('Medico creado exitosamente');
                    $state.go('index.medicos.detalle', {'medicoId':response.data});
                }
            }
            , function (error) {
                vm.medico.medicoSector = [];
                if (error.data.message)
                    toastr.error(error.data.message);
            });
        };
    }
})();