(function () {
    'use strict';

    angular
        .module('app.instituciones')
        .controller('ModificarInstitucionController', ModificarInstitucionController);

    ModificarInstitucionController.$inject = ['institucion', 'potenciales', 'semanas', 'giraCapital', 'sectores', 'dias', 'catalogosService', 'institucionesService',
        'toastr', '$state', 'ERROR_GENERICO', 'DATOS_NO_MODIFICADOS', 'DATOS_MODIFICADOS'];
    
    function ModificarInstitucionController(institucion, potenciales, semanas, giraCapital, sectores, dias, catalogosService, institucionesService,
                                            toastr, $state, ERROR_GENERICO, DATOS_NO_MODIFICADOS, DATOS_MODIFICADOS) {

        var vm = this;

        vm.institucion = institucion;
        vm.estaEditando = true;
        vm.potenciales = potenciales;
        vm.semanas = semanas;
        vm.giraCapital = giraCapital;
        vm.sectores = sectores;
        vm.dias = dias;
        vm.horarios = [{ "horarioNombre": "AM" }, { "horarioNombre": "PM" }];
        vm.obtenerDepartamentos = obtenerDepartamentos;
        vm.modificar = modificar;
        vm.verLista = verLista;

        iniciar();

        function iniciar() {
            var paisId = vm.institucion.ciudad.departamento.pais.paisId;
            var departamentoId = vm.institucion.ciudad.departamento.departamentoId;
            obtenerDepartamentos(paisId);
            obtenerCiudades(departamentoId);
            vm.edited = angular.copy(vm.institucion);
        }

        function obtenerDepartamentos(paisId) {
            catalogosService.getDepartamentos(paisId).then(function (response) {
                vm.departamentos = response;
            });
        }

        function obtenerCiudades(departamentoId) {
            catalogosService.getCiudades(departamentoId).then(function (result) {
                vm.ciudades = result;
            });
        }
        
        function modificar() {

            var diff = {};
            for (var key in vm.institucion) {
                if (!angular.equals(vm.institucion[key], vm.edited[key]))
                    diff[key] = vm.institucion[key];
            }

            if (isEmpty(diff)) {
                toastr.info(DATOS_NO_MODIFICADOS);
                return;
            }

            institucionesService.setModificarInstitucion(vm.institucion).then(function (response) {
                if (response) {
                    toastr.success(DATOS_MODIFICADOS);
                    $state.go('index.instituciones.detalle', {institucionId : institucion.institucionId});
                }
            }, function (error) {
                if (error.data.message)
                    toastr.error(error.data.message);
                else
                    toastr.error(ERROR_GENERICO);
            });
        }

        function isEmpty(object) {
            return angular.equals({}, object);
        }

        function verLista() {
            $state.go('index.instituciones.listar');
        }
    }
})();