angular.module('app.ciclos', [
  'ui.router',
  'toastr',
  'app.ciclosService'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.ciclos', {

          abstract: true,

          url: 'ciclos',

          params: {

          },

          views: {
            '': {
              templateUrl: 'app/ciclos/ciclos.tpl.html',
              resolve: {

              },
              controller: ['$scope', '$state', 'toastr',
                function (  $scope,   $state,   toastr) {
                
                }]
            }
          }
        })
        .state( 'index.ciclos.agregar', {
          url: '/agregar',
          views: {
            '': {
              templateUrl: 'app/ciclos/ciclos.agregar.tpl.html',
              resolve: {
                anios: ['catalogosService',
                  function(catalogosService) {
                    return catalogosService.getAnios();
                  }
                ],
                ciclos: ['catalogosService',
                  function(catalogosService) {
                    return catalogosService.getCiclos();
                  }
                ],
                semanas: ['catalogosService',
                  function(catalogosService) {
                    return catalogosService.getSemanas();
                  }
                ],
              },
              controller: ['$scope', '$state', 'toastr', 'ciclosService', 'catalogosService', 'anios', 'ciclos', 'semanas',
                function (  $scope,   $state,   toastr,   ciclosService,   catalogosService,   anios,   ciclos,   semanas) {
                  //Catalogos
                  $scope.anios = anios;
                  $scope.ciclos = ciclos;
                  $scope.semanas = semanas;
                  //Definiciones
                  $scope.dataCicloAnio = {};
                  $scope.cicloAnio = {};
                  $scope.verSemanas = false;
                    //Funciones

                  function iniciarScope() {
                    for (var i = 1; i < 5; i++) {
                      var elementPos = $scope.cicloAnio.semanaCiclo.map(function(x) {return x.semanaId; }).indexOf(i);
                      var objectFound = $scope.cicloAnio.semanaCiclo[elementPos];
                      if (objectFound == undefined){
                        var dataSemana = {};
                        dataSemana.semanaId = i;
                        dataSemana.semanaCicloId = null;
                        dataSemana.semanaCicloFechaInicio = null;
                        dataSemana.semanaCicloFechaFin = null;
                        $scope.cicloAnio.semanaCiclo.push(dataSemana);
                      }
                    }
                  };

                  $scope.getCicloAnio = function (cicloId, anioId) {
                      ciclosService.getCicloAnio(cicloId, anioId).then(function (result) {
                        $scope.cicloAnio = result;
                        iniciarScope();
                        console.log('$scope.cicloAnio',$scope.cicloAnio);
                        $scope.verSemanas = true;
                      }, function (error) {
                          if (error.status == 404) {
                            toastr.info('DEBE CREAR MANUALMENTE EL CICLO ESPECIFICADO');
                            $scope.cicloAnio.cicloAnioId = null;
                            $scope.cicloAnio.cicloFechaInicio = null;
                            $scope.cicloAnio.cicloFechaFin = null;
                            $scope.cicloAnio.semanaCiclo = [];
                            iniciarScope();
                            $scope.verSemanas = true;
                          } else {
                            if (error.data.message)
                              toastr.error(error.data.message);
                          }
                      });
                  };

                  $scope.submitForm = function(isValid) {
                    if ($scope.cicloAnio.cicloAnioId == null) {
                      $scope.dataCicloAnio.cicloAnio = {};
                      $scope.dataCicloAnio.cicloAnio.fechaInicio = $scope.cicloAnio.cicloFechaInicio;
                      $scope.dataCicloAnio.cicloAnio.fechaFin = $scope.cicloAnio.cicloFechaFin;
                      $scope.dataArraySemanaCiclo = [];
                      for (var j = 0; j < $scope.cicloAnio.semanaCiclo.length; j++) {
                        if ($scope.cicloAnio.semanaCiclo[j].semanaCicloFechaInicio != null && $scope.cicloAnio.semanaCiclo[j].semanaCicloFechaFin != null) {
                          $scope.dataSemanaCiclo = {};
                          $scope.dataSemanaCiclo.semanaId = $scope.cicloAnio.semanaCiclo[j].semanaId;
                          $scope.dataSemanaCiclo.semanaCiclo = {};
                          $scope.dataSemanaCiclo.semanaCiclo.fechaInicio = $scope.cicloAnio.semanaCiclo[j].semanaCicloFechaInicio;
                          $scope.dataSemanaCiclo.semanaCiclo.fechaFin = $scope.cicloAnio.semanaCiclo[j].semanaCicloFechaFin;
                          $scope.dataArraySemanaCiclo.push($scope.dataSemanaCiclo);
                        }
                      }
                      $scope.dataCicloAnio.semanaCiclo = $scope.dataArraySemanaCiclo;
                      ciclosService.setCicloAnio($scope.dataCicloAnio).then(function(response) {
                       
                        if (response.status == 200) {
                          toastr.success(response.data.message);
                          $scope.dataCicloAnio = {};
                        }
                      }, function(error) {
                        toastr.error(error.data.message);
                      });
                    } else {
                      //Quiere modificar
                      swal({
                        title: "¿Está seguro que desea modificar?",
                        text: '',
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        confirmButtonText: "Confirmar",
                        cancelButtonClass: "btn-danger",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                      },
                      function () {
                        $scope.dataCicloAnio.cicloAnio = {};
                        $scope.dataCicloAnio.cicloAnio.cicloAnioId = $scope.cicloAnio.cicloAnioId;
                        $scope.dataCicloAnio.cicloAnio.fechaInicio = moment($scope.cicloAnio.cicloFechaInicio);
                        $scope.dataCicloAnio.cicloAnio.fechaFin = moment($scope.cicloAnio.cicloFechaFin);
                        $scope.dataArraySemanaCiclo = [];
                        for (var j = 0; j < $scope.cicloAnio.semanaCiclo.length; j++) {
                          if ($scope.cicloAnio.semanaCiclo[j].semanaCicloFechaInicio != null && $scope.cicloAnio.semanaCiclo[j].semanaCicloFechaFin != null) {
                            $scope.dataSemanaCiclo = {};
                            $scope.dataSemanaCiclo.semanaId = $scope.cicloAnio.semanaCiclo[j].semanaId;
                            $scope.dataSemanaCiclo.semanaCiclo = {};
                            $scope.dataSemanaCiclo.semanaCiclo.semanaCicloId = $scope.cicloAnio.semanaCiclo[j].semanaCicloId;
                            $scope.dataSemanaCiclo.semanaCiclo.fechaInicio = moment($scope.cicloAnio.semanaCiclo[j].semanaCicloFechaInicio);
                            $scope.dataSemanaCiclo.semanaCiclo.fechaFin = moment($scope.cicloAnio.semanaCiclo[j].semanaCicloFechaFin);
                            $scope.dataArraySemanaCiclo.push($scope.dataSemanaCiclo);
                          }
                        }
                        $scope.dataCicloAnio.semanaCiclo = $scope.dataArraySemanaCiclo;
                        console.log('$scope.dataCicloAnio',$scope.dataCicloAnio);
                        ciclosService.updateCicloAnio($scope.dataCicloAnio).then(function(response) {
                          console.log('response', response);
                          if (response.status == 200) {
                            toastr.success(response.data.message);
                            $scope.dataCicloAnio = {};
                            $scope.cicloAnio = {};
                          }
                        }, function(error) {
                          toastr.error(error.data.message);
                        });
                      });
                    }
                  };

                }]
            }
          }
        })
        .state('index.ciclos.ver', {
            url: '/ver',
            params: {

            },

            views: {
                '': {
                    templateUrl: 'app/ciclos/ciclos.ver.tpl.html',
                    resolve: {
                        anios: ['catalogosService',
                         function (catalogosService) {
                             return catalogosService.getAnios();
                         }]
                    },
                    controller: ['$scope', '$sce', '$state', 'toastr', 'utilsService', 'ciclosService','anios',
                      function ($scope, $sce, $state, toastr, utilsService, ciclosService, anios) {
                          $scope.filtro = {};
                          $scope.anios = anios;
                          $scope.generarReporte = function (anioId) {
                              if (!anioId) {
                                  toastr.info('SELECCIONE UN AÑO');
                                  return;
                              }
                              ciclosService.getCicloDetallesPorAnio(anioId).then(function (result) {
                                  var columns = [
                                      { title: 'Ciclo', dataKey: 'cicloNombre' },
                                      { title: 'Semana', dataKey: 'semanaNombre' },
                                      { title: 'Fecha Inicio', dataKey: 'semanaCicloFechaInicio' },
                                      { title: 'Fecha Fin', dataKey: 'semanaCicloFechaFin' },
                                  ];
                                  var rows = result;
                                  var titulo = 'Ciclo detalles';
                                  $scope.uriPDF = $sce.trustAsResourceUrl(utilsService.reporteTabla(columns, rows, 'l', 'letter', titulo));
                              }, function (error) {
                                  toastr.error(error);
                              });
                          };
                      }]
                }
            }
        })
    }
  ]
);
