angular.module('app.dashboardService', [
  
])

.factory('dashboardService', ['$http', '$q', 'appSettings', function ( $http, $q, appSettings ) {

  return {
      getEstadisticas: function (username, data) {

          var url = appSettings.restApiServiceBaseUri + 'citas/estadisticas';
          var promise = $http.post(url, data).then(function (response) {
              if (response.status == 200)
                  return response.data;
              return response;
          });

          return promise;
      },
      getEstadisticasCitasInstituciones: function (username, data) {

          var url = appSettings.restApiServiceBaseUri + 'citas/estadisticas/instituciones';
          var promise = $http.post(url, data).then(function (response) {
              if (response.status == 200)
                  return response.data;
              return response;
          });

          return promise;
      },
      getEstadisticasMedicamentos: function (username, data) {
          var url = appSettings.restApiServiceBaseUri + 'citas/estadisticas/medicamentos';
          var promise = $http.post(url, data).then(function (response) {
              if (response.status == 200)
                  return response.data;
              return response;
          });

          return promise;
      }
  }
                                                                                                   
}]);