(function () {
    'use strict';

    angular
        .module('app.instituciones', [
            'ui.router',
            'toastr',
            'app.institucionesService',
            'app.catalogosService',
            'ngDialog'
        ])
        .constant('DATOS_NO_MODIFICADOS', 'No se han modificado datos')
        .constant('DATOS_MODIFICADOS', 'Datos modificados con \u00E9xito')
        .constant('INSTITUCION_ELIMINADA', 'Instituci\u00F3n dada de baja exitosamente')
        .constant('DESEA_ELIMINAR_INSTITUCION', '¿Esta seguro que desea dar de baja a esta instituci\u00F3n');
})();