/////////////////////////////
// Parametros del sistema //
/////////////////////////////
var appSettings = {
    isDevelopment: true,
    restApiServiceBaseUri: 'resource/', // REST desarrollo
    restOauth: 'oauth/token', // REST desarrollo
    //downloadBaseUri: 'http://isgttest:58528', // FILES desarrollo
    timeOuttoastrNotifications: '15000',
    paginationPageSizes: [10, 25, 50],
    paginationMinPageSizes: [5, 10, 25],
    smartTable: { itemsByPage: 5, displayedPages: 5 },
    appVersion: '1'
};

/////////////////////////////
// Inicializacion de modulo//
// principal del sistema   //
/////////////////////////////
angular.module('app', [
'templates-app',
'ngSanitize',
'ui.bootstrap',
'ui.grid',
'ui.grid.pagination',
'ui.grid.selection',
'ui.grid.edit',
'ui.grid.autoResize',
'ui.grid.grouping',
'ui.grid.treeView',
'ui.router',
'ui.select',
'ui.calendar',
'mwl.calendar',
'LocalStorageModule',
'chieffancypants.loadingBar',
'toastr',
'ngDialog',
'ui.mask',
'app.utilsService',
'app.authService',
'app.authInterceptorService',
'app.directives',
'app.catalogosService',
'app.login',
'app.medicos',
'app.instituciones',
'app.citas',
'app.usuarios',
'app.ciclos',
'app.solicitudes',
'app.dashboard',
'finalizarCitaMdl',
'iniciarCitaMdl',
'citaDetalleMdl',
'app.medicamentos',
'app.reportes',
'ngTable'
])

.filter('dateFilter', ['$filter', function ($filter) {
    return function (input) {
        if (!input) {
            return '';
        } else {
            return $filter('date')(new Date(input), 'dd/MM/yyyy');
        }
    };
}])

.filter('propsFilter', [function () {
    return function (items, props) {
        var out = [];
        if (angular.isArray(items)) {
            items.forEach(function (item) {
                var itemMatches = false;
                var keys = Object.keys(props);
                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }
                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }
        return out;
    };
}])

.run(
['$rootScope', '$state', '$stateParams', 'appSettings', 'toastr', 'ngDialog',
function ($rootScope, $state, $stateParams, appSettings, toastr, ngDialog) {
    // add references to $state and $stateParams to the $rootScope
    // For example <li ng-class="{ active: $state.includes( 'contacts.list' ) }"> will set the <li>
    // to active whenever 'contacts.list' or one of its decendents is active.
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
        // prevenir accion por default
        event.preventDefault();
        // cerrar todos los dialogos abiertos si hay
        ngDialog.close();
        if (error && error.status == 401) {
            $state.go('login');
        } else {
            console.log("state change error else", { error: error });
            location.href = '#/';
        }
    });
}
]
)

.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('authInterceptorService');
}])

.config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
    cfpLoadingBarProvider.spinnerTemplate = '<div class="spinner"> <div id="loading-bar-spinner"><div class="preloader pl-lg"> <svg class="pl-circular" viewBox="25 25 50 50"><circle class="plc-path" cx="50" cy="50" r="20"></circle></svg> </div></div> </div>';
}])

.config(['ngDialogProvider', function (ngDialogProvider) {
    ngDialogProvider.setDefaults({
        className: 'ngdialog-theme-flat',
        showClose: true,
        closeByDocument: true,
        closeByEscape: true,
        cache: true,
        overlay: true
    });
}])

.config(['toastrConfig', function (toastrConfig) {
    angular.extend(toastrConfig, {
        autoDismiss: false,
        containerId: 'toast-container',
        maxOpened: 0,
        newestOnTop: true,
        positionClass: 'toast-top-right',
        preventDuplicates: false,
        preventOpenDuplicates: false,
        target: 'body'
    });
}])

.config(
['$stateProvider', '$urlRouterProvider',
function ($stateProvider, $urlRouterProvider) {
    var authenticated = ['$location', '$q', 'authService', function ($location, $q, authService) {
        var deferred = $q.defer();
        if (authService.isLoggedIn()) {
            deferred.resolve();
        } else {
            deferred.reject({ status: 401 });
        }
        return deferred.promise;
    }];
    /////////////////////////////
    // Redirects and Otherwise //
    /////////////////////////////
    $urlRouterProvider
    // If the url is ever invalid, e.g. '/asdf', then redirect to '/' aka the home state
    .otherwise('/');
    //////////////////////////
    // State Configurations //
    //////////////////////////
    // Use $stateProvider to configure your states.
    $stateProvider
    //////////
    // Home //
    //////////
    .state("index", {
        abstract: true,
        url: "/",
        templateUrl: 'app/index.tpl.html',
        resolve: {
            menu: ['usuariosService',
                function (usuariosService) {
                    return usuariosService.obtenerMenus().then(function (response) {
                        return response;
                    });
                }
            ]
        },
        controller: ['$scope', '$state', 'toastr', 'appSettings', 'utilsService', 'authService','menu',
        function ($scope, $state, toastr, appSettings, utilsService, authService, menu) {
            // inicializar la plantilla AdminLTE
            //_initAdminLTETemplate();
            $scope.menus = menu;
            $scope.loginData = authService.getLoginData();
            // dateOptions
            $scope.dateOptions = {
                dateDisabled: dateDisabled,
                formatYear: 'yy',
                startingDay: 0,
                format: 'dd/MM/yyyy',
                formatDateTime: 'dd/MM/yyyy HH:mm',
                showMeridian: false,
                daysOfWeekDisabled: [6, 7]
            };
            //Deshabilita fin de semana
            function dateDisabled(data) {
                var date = data.date,
                  mode = data.mode;
                return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
            }
            // objeto fechas que contiene todas las fechas de los forms
            $scope.openedDates = {};
            // funcion que muestra el datepicker
            $scope.openDate = function ($event, field) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.openedDates[field] = true;
            };
            $scope.smartTable = appSettings.smartTable;
            // grid
            $scope.gridOptions = {
                enableRowSelection: true,
                multiSelect: false,
                enableSelectAll: false,
                enableRowHeaderSelection: false,
                selectionRowHeaderWidth: 35,
                groupingRowHeaderWidth: 50,
                enableFiltering: true,
                paginationPageSizes: appSettings.paginationPageSizes,
                data: []
            };
            $scope.gridOptionsSelection = {
                enableRowSelection: true,
                multiSelect: false,
                enableSelectAll: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: appSettings.paginationPageSizes,
                data: []
            };
            $scope.goTab = function (event) {
                event.preventDefault();
            };
            $scope.typeof = function (value, type) {
                return typeof value === type
            };
            $scope.logout = function (isValid) {
                authService.logOut();
                $state.go('login');
            };
        }]
    })
    .state("index.home", {
        url: "",
        views: {
            '': {
                templateUrl: 'app/home/home.tpl.html',
                resolve: {
                    auth: ['authFactory',
                    function (authFactory) {
                        return authFactory._identidad(true);
                    }],
                    usuarios: ['catalogosService','authFactory','auth',
                        function (catalogosService, authFactory, auth) {
                            if (authFactory.estaEnRol('ROLE_ADMIN') || authFactory.estaEnRol('ROLE_SUPERVISOR')) {
                                return catalogosService.getUsuarios().then(function (response) {
                                    return response;
                                });
                            }
                        }
                    ],
                },
                controller: ['$scope', '$state', '$filter', 'ngDialog', 'toastr', 'authService', 'catalogosService', 'citasService', 'usuarios',
                function ($scope, $state, $filter, ngDialog, toastr, authService, catalogosService, citasService, usuarios) {
                    $scope.filtro = {};
                    $scope.usuarios = usuarios;
                    $scope.calendarView = 'month';
                    $scope.calendarDay = new Date();


                    var isAdmin = $scope.loginData.roles.filter(function (item) {
                        return item.authority === 'ROLE_ADMIN' || item.authority === 'ROLE_SUPERVISOR';
                    })

                    $scope.showUserLstBx = isAdmin.length > 0;

                    $scope.setFecha = function (username, fecha) {
                        var date = new Date(fecha);
                        var month = date.getMonth() + 1;
                        var year = date.getFullYear();
                        catalogosService.getCalendario(username, month, year).then(function (result) {
                            $scope.setCalendario(result);
                        }, function (error) {
                            toastr.error(error);
                        });
                    };

                    $scope.getCalendario = function () {
                        var mes = $scope.calendarDay.getMonth() + 1;
                        var anio = $scope.calendarDay.getFullYear();
                        var username = $scope.filtro.username ? $scope.filtro.username : $scope.username;
                        catalogosService.getCalendario(username, mes, anio).then(function (result) {
                            $scope.setCalendario(result);
                        }, function (error) {
                            toastr.error(error);
                        });
                    }

                    $scope.cellIsOpen = true;

                    $scope.setCalendario = function (data) {
                        $scope.events = [];
                        for (var i = 0; i < data.length; i++) {
                            var f_inicio = new Date(data[i].fechaCita);
                            var f_i = $filter('date')(new Date(f_inicio), 'dd/MM/yyyy');
                            var h_i = $filter('date')(new Date(f_inicio), 'HH:mm');
                            var date = f_i;
                            var parts = date.split("/");
                            var time = h_i;
                            var tparts = time.split(":");
                            var dates = new Date(parts[2], parts[1] - 1, parts[0], tparts[0], tparts[1]);
                            switch (data[i].estadoCita) {
                                // Programada
                                case 1:
                                    $scope.events.push({
                                        citaId: data[i].citaId,
                                        title: data[i].tituloCita,
                                        estadoCita: data[i].estadoCita,
                                        startsAt: dates,
                                        color: { primary: '#ffff00', secondary: '#fdfd52' },
                                        editable: false,
                                        deletable: false,
                                        draggable: true,
                                        resizable: true,
                                        allDay: true
                                    });
                                    break;
                                    // Iniciada
                                case 2:
                                    $scope.events.push({
                                        citaId: data[i].citaId,
                                        title: data[i].tituloCita,
                                        estadoCita: data[i].estadoCita,
                                        startsAt: dates,
                                        color: { primary: '#0000FF', secondary: '#3333f9' },
                                        editable: false,
                                        deletable: false,
                                        draggable: true,
                                        resizable: true,
                                        allDay: true
                                    });
                                    break;
                                    // Realizada
                                case 3:
                                    $scope.events.push({
                                        citaId: data[i].citaId,
                                        title: data[i].tituloCita,
                                        estadoCita: data[i].estadoCita,
                                        startsAt: dates,
                                        color: { primary: '#00FF00', secondary: '#3bff3b' },
                                        editable: false,
                                        deletable: false,
                                        draggable: true,
                                        resizable: true,
                                        allDay: true
                                    });
                                    break;
                                    // Cancelada
                                case 4:
                                    $scope.events.push({
                                        citaId: data[i].citaId,
                                        title: data[i].tituloCita,
                                        estadoCita: data[i].estadoCita,
                                        startsAt: dates,
                                        endsAt: dates,
                                        color: { primary: '#FF0000', secondary: '#fb4444' },
                                        editable: false,
                                        deletable: false,
                                        draggable: true,
                                        resizable: true,
                                        allDay: true,
                                    });
                                    break;
                            }
                        };
                    };

                    $scope.goCita = function (evento) {
                        if (evento.estadoCita == 1) {
                            iniciarCita(evento.citaId, evento.title);
                        } else if (evento.estadoCita == 3) {
                            verCitaFinalizada(evento.citaId, evento.title);
                        } else if (evento.estadoCita == 4) {
                            verCitaCancelada(evento.citaId);
                        }
                    };

                    $scope.timespanClicked = function (date, cell) {
                        if ($scope.calendarView === 'month') {
                            if (($scope.cellIsOpen && moment(date).startOf('day').isSame(moment($scope.viewDate).startOf('day'))) || cell.events.length === 0 || !cell.inMonth) {
                                $scope.cellIsOpen = false;
                            } else {
                                $scope.cellIsOpen = true;
                                $scope.viewDate = date;
                            }
                        } else if ($scope.calendarView === 'year') {
                            if (($scope.cellIsOpen && moment(date).startOf('month').isSame(moment($scope.viewDate).startOf('month'))) || cell.events.length === 0) {
                                $scope.cellIsOpen = false;
                            } else {
                                $scope.cellIsOpen = true;
                                $scope.viewDate = date;
                            }
                        }
                    };

                    //iniciarCita
                    function iniciarCita(citaId, title) {

                        $scope.iniciarCitaInfo = {};
                        $scope.iniciarCitaInfo.citaId = citaId;
                        $scope.iniciarCitaInfo.title = title;

                        ngDialog.open({
                            template: 'app/citas/citas.iniciar.tpl.html',
                            className: 'ngdialog-theme-flat ngdialog-theme-custom',
                            controller: 'iniciarCitaCtrl',
                            scope: $scope
                        });
                    }

                    // Detalles cita finalizada
                    function verCitaFinalizada(citaId, titulo) {
                        $scope.citaFinalizada = {};
                        $scope.citaFinalizada.citaId = citaId;
                        $scope.citaFinalizada.titulo = titulo;
                        ngDialog.open({
                            template: 'app/citas/citas.detalle.tpl.html',
                            className: 'ngdialog-theme-flat ngdialog-theme-custom',
                            controller: 'citaFinalizadaCtrl',
                            scope: $scope
                        });
                    }

                    // Detalles cita cancelada
                    function verCitaCancelada(citaId) {
                        $scope.citaCancelada = {};
                        $scope.citaCancelada.citaId = citaId;
                        ngDialog.open({
                            template: 'app/citas/citas.detalleCancelada.tpl.html',
                            className: 'ngdialog-theme-flat ngdialog-theme-custom',
                            scope: $scope,
                            controller: ['$scope', 'citasService', function ($scope, citaService) {

                                citasService.getCita($scope.citaCancelada.citaId).then(function (response) {
                                    for (var key in response) {
                                        $scope.citaCancelada[key] = response[key];
                                    }
                                }, function (error) {
                                    console.log(error);
                                });

                                $scope.citaCancelada.aceptar = function () {
                                    $scope.closeThisDialog();
                                }

                            }]
                        });
                    }

                    $scope.cancelarCita = function cancelarCita(cancelarCitaInfo) {

                        var citaId = cancelarCitaInfo.citaId;
                        var title = cancelarCitaInfo.title;
                        var startsAt = cancelarCitaInfo.startsAt;
                        var endsAt = cancelarCitaInfo.endsAt;
                        var motivoCancelacion = cancelarCitaInfo.motivoCancelacion;

                        citasService.setCancelarCita(citaId, motivoCancelacion).then(function (response) {
                            if (response) {

                                ngDialog.close();

                                toastr.error('AVISO: Cita cancelada');

                                var cita = $scope.events.filter(function (item) {
                                    return item.citaId == citaId;
                                })

                                //Cambiando a estado cancelada
                                var nuevaCita = {
                                    citaId: citaId,
                                    title: title,
                                    estadoCita: 4, // Cancelada
                                    startsAt: startsAt,
                                    endsAt: endsAt,
                                    color: { primary: '#FF0000', secondary: '#fb4444' },
                                    editable: false,
                                    deletable: false,
                                    draggable: true,
                                    resizable: true,
                                    allDay: true
                                };

                                var index = $scope.events.indexOf(cita[0]);
                                if (index !== -1) {
                                    $scope.events[index] = nuevaCita;
                                }


                            }
                        }, function (error) {
                            toastr.error(error.data.message);
                        });
                    }

                    $scope.loginData = authService.getLoginData();
                    $scope.username = $scope.loginData.username;
                    $scope.setFecha($scope.username, $scope.calendarDay);

                }]
            },
            'hint@index': {
                template: '<h1>Posts</h1>'
            }
        }
    })
}
]
)

.constant('appSettings', appSettings)
    .constant('ERROR_GENERICO', 'Ha ocurrido un error');