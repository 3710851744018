(function () {
    'use strict';

    angular
        .module('app.instituciones')
        .controller('AgregarInstitucionesController', AgregarInstitucionesController);

    AgregarInstitucionesController.$inject = ['tiposInstitucion', 'cadenasFarmacia', 'potenciales', 'paises', 'semanas', 'giraCapital', 'sectores', 'dias', 'catalogosService', 'institucionesService',
        'toastr', '$state', 'ERROR_GENERICO', ];

    function AgregarInstitucionesController(tiposInstitucion, cadenasFarmacia, potenciales, paises, semanas, giraCapital, sectores, dias, catalogosService, institucionesService,
                                            toastr, $state, ERROR_GENERICO) {

        var vm = this;

        vm.institucion = {}
        vm.potenciales = potenciales;
        vm.semanas = semanas;
        vm.giraCapital = giraCapital;
        vm.sectores = sectores;
        vm.dias = dias;
        vm.tiposInstitucion = tiposInstitucion;
        vm.horarios = [{ "horarioNombre": "AM" }, { "horarioNombre": "PM" }];
        vm.paises = paises;
        vm.cadenasFarmacia = cadenasFarmacia;
        vm.getDepartamentos = getDepartamentos;
        vm.getCiudades = getCiudades;
        vm.guardar = guardar;

        function getDepartamentos (paisId) {
            catalogosService.getDepartamentos(paisId).then(function (result) {
                vm.departamentos = result;
            });
        };

        function getCiudades (departamentoId) {
            catalogosService.getCiudades(departamentoId).then(function (result) {
                vm.ciudades = result;
            });
        };
        
        function guardar() {

            if (vm.giraCapitalId == 1) {
                vm.institucion.gira = true;
                vm.institucion.capital = false;
            } else {
                vm.institucion.gira = false;
                vm.institucion.gira = true;
            }

            institucionesService.setInstitucion(vm.institucion).then(function (response) {
                    if (response.status == 201) {
                        toastr.success('Instituci\u00F3n creada exitosamente');
                        $state.go('index.instituciones.detalle', {'institucionId' : response.data});
                    }
                }
                , function (error) {
                    if (error.data.message)
                        toastr.error(error.data.message);
                });
        }
    }
})();