(function () {
    'use strict';

    angular
       .module('app.dashboard', [
                'ui.router',
                'app.dashboardService',
                'googlechart',
                'app.authService']);
   
})();

