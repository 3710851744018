(function () {
    'use strict';

    angular
        .module('app.medicos')
        .controller('ListarMedicosController', ListarMedicosController);


    ListarMedicosController.$inject = ['medicos', 'uiGridConstants', '$state', 'NgTableParams', 'utilsService', 'auth',
        'authFactory','usuarios','medicosService','toastr', 'sectores', 'medicamentos'];

    function ListarMedicosController(medicos, uiGridConstants, $state, NgTableParams, utilsService, auth, authFactory, usuarios,
                                     medicosService, toastr, sectores, medicamentos) {
        
        var vm = this;
        vm.tablaMedicos = new NgTableParams({}, {dataset: medicos});
        vm.mostrarDetalles = mostrarDetalles;
        vm.imprimirReporte = imprimirReporte;
        vm.esAdmin = authFactory.estaEnRol('ROLE_ADMIN');
        vm.esSupervisor = authFactory.estaEnRol('ROLE_SUPERVISOR');
        vm.usuarios = usuarios;
        vm.sectores = sectores;
        vm.medicamentos = medicamentos
        vm.filtros = {};
        vm.filtrarMedico = filtrarMedico;

        function filtrarMedico() {
            if(angular.equals({}, vm.filtros))
            {
                toastr.info('Debe seleccionar al menos un filtro');
                return;
            }
            medicosService.filtrarListaMedicos(vm.filtros).then(function (response) {
                medicos = response;
                vm.tablaMedicos.settings({ dataset: medicos });
                vm.tablaMedicos.reload();
            }, function (error) {
                console.log('error');
            });
        }

        function mostrarDetalles(medicoId) {
            $state.go('index.medicos.detalle', { medicoId: medicoId });
        }

        function imprimirReporte() {
            utilsService.reporteUniversos(medicos,'medico');
        }
    }
})();