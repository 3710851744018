(function () {
    'use strict';

    angular
        .module('app')
        .factory('authFactory', authFactory);

    authFactory.$inject = ['$q', '$http'];

    function authFactory($q, $http) {
        var identidad = undefined,
        autenticado = false;

        return {
            identidadResuelta: function () {
                return angular.isDefined(autenticado);
            },
            estaAutenticado: function () {
                return autenticado;
            },
            estaEnRol: function (rol) {
                if (!autenticado || !identidad.roles) return false;
                
                return identidad.roles.filter(function (item) { return item.authority === rol }).length > 0;
            },
            autenticar: function (_identidad) {
                identidad = _identidad;
                autenticado = _identidad != null;
            },
            _identidad: function (forzar) {
                var deferred = $q.defer();

                if (forzar === true) identidad = undefined;

                if (angular.isDefined(identidad)) {
                    deferred.resolve(identidad);

                    return deferred.promise;
                }

                $http.get(appSettings.restApiServiceBaseUri + '/usuarios/current-user').then(function (response) {
                    identidad = response.data;
                    autenticado = true;
                    deferred.resolve(identidad);
                }, function (error) {
                    identidad = null;
                    autenticado = false;
                    deferred.reject(error);
                });

                return deferred.promise;
            }
        }
    }

})();