(function () {
    'use strict';

    angular
        .module('app.solicitudes')
        .factory('solicitudesService', solicitudesService);
    
    solicitudesService.$inject = ['$http', '$q', 'appSettings'];
    
    function solicitudesService($http, $q, appSettings) {

        var baseUrl = appSettings.restApiServiceBaseUri + 'solicitudes-cambio/';

        return {
            obtenerSolicitudes: function () {

                return $http.get(baseUrl).then(function (response) {
                    if(response.status == 200)
                        return response.data;
                    else
                        return $q.reject;
                });
            },
            aprobarSolicitud: function (solicitudCambioId) {

                var url = baseUrl + solicitudCambioId + '/aprobar';
                return $http.put(url).then(function (response) {
                    return response;
                });
            },
            rechazarSolicitud: function (solicitudCambioId) {

                var url = baseUrl + solicitudCambioId + '/rechazar';
                return $http.put(url).then(function (response) {
                   return response;
                });
            },
            obtenerOperaciones: function () {
                var url = baseUrl + 'operaciones-cambio';
                return $http.get(url).then(function (response) {
                    if (response.status == 200)
                        return response.data;
                    else
                        return $q.reject;
                });
            },
            crearSolicitudes: function (data) {
                return $http.post(baseUrl, data).then(function (response) {
                    if (response.status == 204)
                        return true;
                    else
                        return $q.reject;
                });
            },
            validarModificar: function (data) {
                var url = baseUrl + 'validar-modificar';
                return $http.post(url, data).then(function (response) {
                   if (response.status == 202)
                       return true;
                   else
                       return $q.reject;
                });
            },
            validarEliminar: function (data) {
                var url = baseUrl + 'validar-eliminar';
                return $http.post(url, data).then(function (response) {
                    if (response.status == 202)
                        return true;
                    else
                        return false;
                }, function (error) {
                    return false;
                });
            }
        }
    }
})();