(function () {
    'use strict';

    angular
        .module('app.solicitudes')
        .controller('ListarSolicitudesController', ListarSolicitudesController);


    ListarSolicitudesController.$inject = ['solicitudes', 'NgTableParams', 'solicitudesService', 'toastr', 'SOLICITUD_APROBADA', 'SOLICITUD_RECHAZADA']

    function ListarSolicitudesController(solicitudes, NgTableParams, solicitudesService, toastr, SOLICITUD_APROBADA, SOLICITUD_RECHAZADA) {

        var vm = this;
        vm.tablaSolicitudes = new NgTableParams({}, { dataset: solicitudes });
        vm.aprobarSolicitud = aprobarSolicitud;
        vm.rechazarSolicitud = rechazarSolicitud;

        function aprobarSolicitud(solicitudCambioId, indice) {
            solicitudesService.aprobarSolicitud(solicitudCambioId).then(function (response) {
                eliminarFila(indice);
                toastr.success(SOLICITUD_APROBADA);
            }, function (error) {
                toastr.error('Ha ocurrido un error');
            });
        }

        function rechazarSolicitud(solicitudCambioId, indice) {
            solicitudesService.rechazarSolicitud(solicitudCambioId).then(function (response) {
                eliminarFila(indice);
                toastr.success(SOLICITUD_RECHAZADA)
            }, function (error) {
                toastr.error('Ha ocurrido un error');
            });
        }
        
        function eliminarFila(indice) {
            solicitudes.splice(indice, 1);
            vm.tablaSolicitudes.reload();
        }
    }
})();