angular.module('app.ciclosService', [
  
])

.factory('ciclosService', ['$http', '$q', 'appSettings', function ( $http, $q, appSettings ) {
  return {
  	getCicloAnio: function(cicloId,anioId) {
      var promise = $http.get(appSettings.restApiServiceBaseUri + 'ciclos/' + cicloId + '/anios/' + anioId).then(function (response) {
        if (response.status == 200)
          return response.data
      });
      return promise;
    },
    setCicloAnio: function (data) {
      var promise = $http.post(appSettings.restApiServiceBaseUri + 'cicloAnio', data).then(function (response) {
        return response;
      });
      return promise;
    },
    updateCicloAnio: function (data) {
      var promise = $http.put(appSettings.restApiServiceBaseUri + 'cicloAnio', data).then(function (response) {
        return response;
      });
      return promise;
    },
    getCicloDetallesPorAnio: function (anioId) {
        var url = appSettings.restApiServiceBaseUri + 'ciclos/' + anioId + '/detalle';
        return $http.get(url).then(function (response) {
            if (response.status == 200)
                return response.data;
        });
    }
  }
}]);