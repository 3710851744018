// JavaScript source code
var iniciarCitaMdl = angular.module('iniciarCitaMdl', ['app.catalogosService', 'app.citasService', 'toastr']);

iniciarCitaMdl.controller('iniciarCitaCtrl', ['$scope', 'catalogosService', 'citasService', 'toastr',
    function ($scope, catalogosService, citasService, toastr) {

        $scope.detalleCita = {};
        $scope.detalleCita.citaAnterior = {};
        $scope.detalleCita.citaId = $scope.iniciarCitaInfo.citaId;

        citasService.getDetalleCita($scope.iniciarCitaInfo.citaId).then(function (response) {
            // Cargamos la info del medico si existe.
            if (response.citaActual.medico) {
                $scope.esMedico = true;
                for (var key in response.citaActual.medico) {
                    $scope.detalleCita[key] = response.citaActual.medico[key];
                }
            }
            // Cargamos la info de la institucion si existe.
            else if (response.citaActual.institucion) {
                for (var key in response.citaActual.institucion) {
                    $scope.detalleCita[key] = response.citaActual.institucion[key];
                }
                $scope.esMedico = false;
            }
            if (response.citaAnterior) {

                for (var key in response.citaAnterior) {
                    $scope.detalleCita.citaAnterior[key] = response.citaAnterior[key];
                }
                console.log($scope.detalleCita.citaAnterior);
            }
        }, function (error) {
            if (error.data.message) {
                toastr.error(error.data.message);
            } else {
                toastr.error(error);
            }
        });

        $scope.cancelarIniciarCita = function () {
            $scope.closeThisDialog();
        }

        $scope.iniciarCita = function (iniciarCitaInfo) {

            var citaId = iniciarCitaInfo.citaId;
            var title = iniciarCitaInfo.title;
            var startsAt = new Date();

            citasService.setIniciarCita(citaId).then(function (status) {

                if (status == 204) {
                    toastr.success('AVISO: Cita iniciada exitosamente');
               
                    var cita = $scope.events.filter(function (item) {
                        return item.citaId == citaId;
                    })

                    var nuevaCita = {
                        citaId: citaId,
                        title: title,
                        estadoCita: 2, // Iniciada
                        startsAt: startsAt,
                        color: { primary: '#0000FF', secondary: '#3333f9' },
                        editable: false,
                        deletable: false,
                        draggable: true,
                        resizable: true,
                        allDay: true,
                        actions: [{
                            label: '<i class=\'fa fa-sign-out text-green\' title="Finalizar"></i>',
                            onClick: function (args) {
                                // TODO implementar finalizar cita
                                $scope.completarCita(args.calendarEvent.citaId, args.calendarEvent.title);
                            }
                        },
                        {
                            label: '<i class=\'fa fa-times text-red\' title="Cancelar"></i>',
                            onClick: function (args) {
                                modalCancelarCitas(args.calendarEvent.citaId,
                                           args.calendarEvent.title,
                                           args.calendarEvent.estadoCita,
                                           args.calendarEvent.startsAt,
                                           args.calendarEvent.endsAt);
                            }
                        }]
                    };

                    var index = $scope.events.indexOf(cita[0]);
                    if (index !== -1) {
                        $scope.events[index] = nuevaCita;
                    }
                    $scope.closeThisDialog();
                } else {
                    toastr.error('ERROR: Cita no se pudo iniciar');
                }
            }, function (error) {
                toastr.error(error.message);
            });
        };

    }]);