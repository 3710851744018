(function () {

    'use strict';

    angular
        .module('app.solicitudes')
        .config(config);

    function config($stateProvider) {
        $stateProvider
            .state('index.solicitudes', {
                url: 'solicitudes',
                abstract: true,
                template: '<ui-view/>'
            })
            .state('index.solicitudes.list', {
                url: '/ver',
                templateUrl: 'app/autorizaciones/autorizaciones.list.tpl.html',
                controller: 'ListarSolicitudesController',
                controllerAs: 'vm',
                resolve: {
                    solicitudes: solicitudesService
                }
            })
            .state('index.solicitudes.crear', {
                url: '/crear',
                templateUrl: 'app/autorizaciones/crearSolicitudes.tpl.html',
                controller: 'CrearSolicitudesController',
                controllerAs: 'vm',
                resolve: {
                    instituciones: institucionesService,
                    medicos: medicosService,
                    operaciones: operacionesCambioService
                }
            })
        ;
    }
    
    function solicitudesService(solicitudesService) {
        return solicitudesService.obtenerSolicitudes();
    }

    function institucionesService(institucionesService) {
        return institucionesService.listInstituciones();
    }

    function medicosService(medicosService) {
        return medicosService.listMedicos();
    }

    function operacionesCambioService(solicitudesService) {
        return solicitudesService.obtenerOperaciones();
    }
})();