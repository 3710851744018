angular.module( 'app.login', [
  'ui.router',
  'toastr',
  'app.authService',
  'app.catalogosService'
])
  
.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        //////////////
        // Login //
        //////////////
        .state( 'login', {

          url: '/login',

          templateUrl: 'app/login/login.tpl.html',

          controller: ['$scope', '$state', '$timeout', 'toastr', 'authService', 'catalogosService',
            function (  $scope,   $state,   $timeout,   toastr,   authService,   catalogosService) {
              $scope.submitForm = function (isValid) {
                if (isValid) {
                  authService.login($scope.loginData).then( function ( response ) {
                    $scope.response = response;
                    if ( !response.err ) {
                      $state.go( 'index.home' );
                    } else {
                      $timeout( function () {$scope.form.$submitted = false;}, 1300);
                    }
                  }, function ( error ) {
                      toastr.error(error.data.error_description);
                  });
                }
              }
            }]
        })
    }
  ]
);
