angular.module('app.reportes', [
  'ui.router',
  'toastr',
  'app.catalogosService',
  'app.reportesService',
  'app.citasService'
])
  
.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.reportes', {

          abstract: true,

          url: 'reportes',

          params: {
            
          },

          views: {
            '': {
              templateUrl: 'app/reportes/reportes.tpl.html',
              resolve: {

              },
              controller: ['$scope', '$state', 'toastr',
                function (  $scope,   $state,   toastr) {

                  
                  
                }]
            }
          }
          
        })
        .state( 'index.reportes.reporte1', {

          url: '',

          views: {
            '': {
              templateUrl: 'app/reportes/reportes.reporte1.tpl.html',
              resolve: {
                paises: ['catalogosService',
                  function(catalogosService){
                  return catalogosService.getPaises();
                  }
                ],
                anios: ['catalogosService',
                  function(catalogosService) {
                    return catalogosService.getAnios();
                  }
                ],
                ciclos: ['catalogosService',
                  function(catalogosService) {
                    return catalogosService.getCiclos();
                  }
                ],
                semanas: ['catalogosService',
                  function(catalogosService) {
                    return catalogosService.getSemanas();
                  }
                ],
                  auth: ['authFactory', function (authFactory) {
                      return authFactory._identidad(true);
                  }],
                usuarios: ['catalogosService', 'authFactory','auth',
                  function(catalogosService, authFactory, auth){
                      if (authFactory.estaEnRol('ROLE_ADMIN') || authFactory.estaEnRol('ROLE_SUPERVISOR')) {
                          return catalogosService.getUsuarios();
                      }
                  }
                ],
                medicamentos: ['catalogosService',
                  function(catalogosService) {
                    return catalogosService.getMedicamentos();
                  }
                ],
                especialidades: ['catalogosService',
                  function(catalogosService) {
                    return catalogosService.getEspecialidades();
                  }
                ],
                tiposInstitucion: ['catalogosService',
                    function (catalogosService) {
                        return catalogosService.getTiposInstitucion();
                    }
                ],
                sectores: ['catalogosService',
                    function (catalogosService) {
                        return catalogosService.getAllSectores().then(function (response) { return response; }, function (error) { return null; });
                    }
                ],
                estados: ['catalogosService',
                    function (catalogosService) {
                        return catalogosService.getEstadosVisita().then(function (response) { return response; }, function (error) { return null;});
                    }
                ]
              },
              controller: ['$scope', '$sce', '$state', '$filter', 'NgTableParams', 'toastr', 'utilsService', 'reportesService', 'catalogosService',
                  'paises', 'anios', 'ciclos', 'semanas', 'usuarios', 'medicamentos', 'especialidades', 'citasService', 'tiposInstitucion','sectores','estados',
                function ($scope, $sce, $state, $filter, NgTableParams, toastr, utilsService, reportesService, catalogosService,
                    paises, anios, ciclos, semanas, usuarios, medicamentos, especialidades, citasService, tiposInstitucion, sectores, estados) {
                    //Catalogos
                  $scope.paises = paises;
                  $scope.anios = anios;
                  $scope.ciclos = ciclos;
                  $scope.semanas = semanas;
                  $scope.usuarios = usuarios;
                  $scope.medicamentos = medicamentos;
                    //Definiciones
                  $scope.requestReporte = {};
                  $scope.vistasPendientesReq = {};
                  $scope.departamentos = [];
                  $scope.ciudades = [];
                  $scope.sectores = [];
                  $scope.data = {};
                  $scope.resultData = [];
                  $scope.vistaReporte = 0;
                  $scope.especialidades = especialidades;
                  $scope.tiposInstitucion = tiposInstitucion;
                  $scope.totales = {};
                    
                    //scope filtros

                  $scope.filtroCliente = {
                      'medico.nombre': {
                          id: 'text',
                          placeholder:'Medico'
                      },
                      institucion: {
                          id: 'text',
                          placeholder: 'Institucion'
                      }
                  }
                    //

                  var estado;

                  $scope.esAdmin = $scope.loginData.roles.filter(function (item) {
                      return item.authority === 'ROLE_ADMIN' || item.authority === "ROLE_SUPERVISOR";
                  }).length > 0;

                  if (sectores != null)
                      $scope.sectores = sectores;

                  if (estados != null)
                      $scope.estados = estados;
                  //Funciones
                  $scope.getDepartamentos = function (paisId) {
                      if (paisId) {
                          catalogosService.getDepartamentos(paisId).then(function (result) {
                              $scope.departamentos = result;
                          }, function (error) {
                              toastr.error(error);
                          });
                      }
                  };
                  $scope.getCiudades = function (departamentoId) {
                      if (departamentoId) {
                          catalogosService.getCiudades(departamentoId).then(function (result) {
                              $scope.ciudades = result;
                          }, function (error) {
                              toastr.error(error);
                          });
                      }
                  };

                  $scope.getSectoresPorUsername = function (username) {

                      if (username) {
                          catalogosService.getSectoresPorUsername(username).then(function (result) {
                              $scope.sectores = result;
                          }, function (error) {
                              toastr.error('Ha ocurrido un error');
                          });
                      } else {
                          catalogosService.getAllSectores().then(function (response) {
                              $scope.sectores = response;
                          }, function (error) {
                              toastr.error('Ha ocurrido un error');
                          });
                      }
                  };

                

                  $scope.getMedicos = function (sectorId, especialidadId) {
                      if (sectorId) {
                          citasService.getMedicosSectorEspecialidad(sectorId, especialidadId).then(function (response) {
                              $scope.medicos = response;
                          }, function (error) {
                              console.log('Ha ocurrido un error');
                          });
                      } else if(especialidadId) {
                          toastr.info('Debe seleccionar un sector en \u00C1rea Geogr\u00E1fica para ver los medicos disponibles');
                      }
                  };

                  $scope.getInstituciones = function (sectorId, tipoId) {

                      if (sectorId) {
                          citasService.getInstitucionSectorTipoInstitucion(sectorId, tipoId).then(function (response) {
                              $scope.instituciones = response;
                          });
                      } else if (tipoId) {
                          toastr.info('Debe seleccionar un sector en \u00C1rea Geogr\u00E1fica para ver las instituciones disponibles');
                      }
                  }

                  $scope.getClientes = function (sectorId) {

                      if ($scope.data.especialidadId) {

                          $scope.getMedicos(sectorId, $scope.data.especialidadId);
                      }

                      if ($scope.data.tipoInstitucionId) {
                          $scope.getInstituciones(sectorId, $scope.data.tipoInstitucionId);
                      }
                  }

                  $scope.generarReporte=function(){
                   
                      if ($scope.requestReporte.medicoId || $scope.requestReporte.especialidadId) {

                          delete $scope.requestReporte.sectorId;
                      }

                     

                    reportesService.getCitasReporte($scope.requestReporte).then(function(response) {
                      if(response.status == 200){
                          $scope.resultData = response.data;
                        $scope.tableReporte = new NgTableParams({}, { dataset: $scope.resultData.visitas});
                        $scope.vistaReporte = 1;
                        $scope.totales.medicos = response.data.mCount;
                        $scope.totales.instituciones = response.data.iCount;
                        estado = $scope.requestReporte.estado

                        if (estado) {
                            if (estado == 1)
                                $scope.tablaTitulo = 'Visitas pendientes';
                            if (estado == 2)
                                $scope.tablaTitulo = 'Visitas no completadas';
                            if (estado == 3)
                                $scope.tablaTitulo = 'Visitas completadas';
                            if (estado == 4)
                                $scope.tablaTitulo = 'Visitas canceladas';
                        }
                        else {
                            $scope.tablaTitulo = 'Visitas completadas';
                        }
                      } else {
                        toastr.warning("AVISO: No hay resultados para el reporte " + responses);
                      }
                    }
                    , function (error) {
                        if (error.data) {
                            toastr.error(error.data.message);
                        }
                        console.log(error);
                    });
                  };
                  $scope.setBack=function(){
                      $scope.vistaReporte = 0;
                      $scope.vistaReportePendientes = 0;
                  };
                  $scope.imprimirReporte=function(){
                    if ($scope.resultData.visitas.length > 0){
                      utilsService.reportesCitas($scope.resultData, estado);
                    } else {
                      toastr.error("ERROR: No hay datos para imprimir el reporte.");
                    }
                  };

                    //reporte visitas pendientes
                  $scope.reporteVisitasPendientes = function () {
                      if (!$scope.esAdmin) {
                          $scope.vistasPendientesReq.username = '';
                      }
                      reportesService.visitasPendientes($scope.vistasPendientesReq).then(function (response) {  
                          $scope.totales.medicos = response.mCount;
                          $scope.totales.instituciones = response.iCount;
                          $scope.resultData = response;
                          $scope.tableReportePendientes = new NgTableParams({}, { dataset: $scope.resultData.visitas });
                          $scope.vistaReportePendientes = 1;
                      }, function (error) {
                          if (error.data) {
                              toastr.error(error.data.message);
                          }
                      });
                  };
                }]
            }
          }
        })
    }
  ]
);