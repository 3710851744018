angular.module('app.usuariosService', [])

    .factory('usuariosService', ['$http', '$q', 'appSettings', function ($http, $q, appSettings) {
        return {
            getSectores: function () {
                var url = appSettings.restApiServiceBaseUri + 'sectores';
                return $http.get(url).then(function (response) {
                    if (response.status == 200) {
                        return response.data;
                    }

                    return resposne;
                });
            },
            getRoles: function () {
                var url = appSettings.restApiServiceBaseUri + 'roles';
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            setUsuario: function (data) {
                var url = appSettings.restApiServiceBaseUri + 'usuarios';

                return $http.post(url, data).then(function (response) {
                    return response.status == 201;
                });
            },
            obtenerUsuarioPorId: function (usuarioId) {
                var url = appSettings.restApiServiceBaseUri + 'usuarios/' + usuarioId;

                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            modificarUsuario: function (data) {
                var url = appSettings.restApiServiceBaseUri + 'usuarios/modificar';

                return $http.put(url, data).then(function (response) {
                    return response.status == 204;
                });
            },
            getSectoresNoAsignados: function () {
                var url = appSettings.restApiServiceBaseUri + 'sectores/no-asignados';
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            setCambiarPassword: function (data) {
                var url = appSettings.restApiServiceBaseUri + 'usuarios/cambiarPassword';
                return $http.put(url, data).then(function (response) {
                    return response.status == 204;
                });
            },
            getVisitadores: function () {
                var url = appSettings.restApiServiceBaseUri + 'roles/1/usuarios';
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            getMedicos: function (username) {
                var url = appSettings.restApiServiceBaseUri + 'medicos/usuario/' + username;
                return $http.get(url).then(function (response) {
                    return response.data;
                });
            },
            obtenerMenus: function () {
                var url = appSettings.restApiServiceBaseUri + 'usuarios/usuario-actual/menu';
                return $http.get(url).then(function (response) {
                   return response.data;
                });
            }
        }
    }]);