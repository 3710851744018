(function () {
    'use strict';

    angular
        .module('app.solicitudes')
        .controller('CrearSolicitudesController', CrearSolicitudesController);


    CrearSolicitudesController.$inject = ['instituciones', 'medicos', 'NgTableParams', 'operaciones', 'solicitudesService', 'toastr', 'SOLICITUDES_CREADAS', 'ERROR_GENERICO',
        'MEDICO_INSTITUCION_NO_SELECCIONADO', 'OPERACION_NO_SELECCIONADA', 'DESCRIPCION_NO_AGREGADA'];
    
    function CrearSolicitudesController(instituciones, medicos, NgTableParams, operaciones, solicitudesService, toastr, SOLICITUDES_CREADAS, ERROR_GENERICO,
                                        MEDICO_INSTITUCION_NO_SELECCIONADO, OPERACION_NO_SELECCIONADA, DESCRIPCION_NO_AGREGADA) {

        var vm = this;

        vm.mostrarTablaMedicos = true;
        vm.mostrarTablaInstituciones = false;
        vm.solicitudes = [];
        vm.solicitudCambio = {};
        vm.solicitudCambio.medicoId = null;
        vm.solicitudCambio.institucionId = null;
        vm.tablaMedicos = new NgTableParams({
            page: 1,
            count: 5
        }, {
            dataset: medicos
        });
        vm.tablaInstituciones = new NgTableParams({
            page: 1,
            count: 5
        }, {
           dataset: instituciones
        });
        vm.operaciones = operaciones;

        vm.agregar = agregar;
        vm.eliminar = eliminar;
        vm.crearSolicitudes = crearSolicitudes;
        vm.seleccionarMedico = seleccionarMedico;
        vm.seleccionarInstitucion = seleccionarInstitucion;
        vm.mostrarMedicos = mostrarMedicos;
        vm.mostrarInstituciones = mostrarInstituciones;
        
        function seleccionarMedico(id, nombre) {
            vm.idMedicoSeleccionado = id;
            vm.solicitudCambio.medicoId = id;
            vm.solicitudCambio.nombre = nombre;
            vm.solicitudCambio.institucionId = null;
        }
        
        function seleccionarInstitucion(id, nombre) {
            vm.idInstitucionSeleccionada = id;
            vm.solicitudCambio.institucionId = id;
            vm.solicitudCambio.nombre = nombre;
            vm.solicitudCambio.medicoId = null;
        }
        
        function agregar() {

            if (esValido()) {

                var exists = vm.solicitudes.find(function (item) {
                    return puedeAgregar(item);
                });

                if (!exists) {
                    var solicitudTmp = {};
                    solicitudTmp.medicoId = vm.solicitudCambio.medicoId;
                    solicitudTmp.institucionId = vm.solicitudCambio.institucionId;
                    solicitudTmp.operacionId = vm.solicitudCambio.operacion.id;
                    solicitudTmp.descripcion = vm.solicitudCambio.descripcion;
                    //Informacion para la tabla
                    solicitudTmp.nombre = vm.solicitudCambio.nombre;
                    solicitudTmp.operacion = vm.solicitudCambio.operacion.nombre;

                    vm.solicitudes.push(solicitudTmp);
                    vm.solicitudCambio.descripcion = null;
                }
            }
        }

        function eliminar(index) {

            vm.solicitudes.splice(index,1);
        }


        function puedeAgregar(item) {
            if (vm.solicitudCambio.institucionId == null)
                return angular.equals(item.medicoId, vm.solicitudCambio.medicoId);
            else if(vm.solicitudCambio.medicoId == null)
                return angular.equals(item.institucionId, vm.solicitudCambio.institucionId);
        }
        
        function crearSolicitudes() {
            var request = {};
            request.solicitudCambioList = vm.solicitudes;
            solicitudesService.crearSolicitudes(request).then(function (response) {
                if (response)
                    toastr.success(SOLICITUDES_CREADAS);
            }, function (error) {
                if (error.data)
                    if (error.data.message)
                        toastr.error(error.data.message);
                else
                    toastr.error(ERROR_GENERICO);
            });
        }

        function mostrarMedicos() {
            vm.mostrarTablaMedicos = true;
            vm.mostrarTablaInstituciones = false;
        }

        function mostrarInstituciones() {
            vm.mostrarTablaMedicos = false;
            vm.mostrarTablaInstituciones = true;
        }
        
        function esValido() {
            if (vm.solicitudCambio.medicoId == null && vm.solicitudCambio.institucionId == null) {
                toastr.info(MEDICO_INSTITUCION_NO_SELECCIONADO);
                return false;
            }
            else if(!vm.solicitudCambio.operacion) {
                toastr.info(OPERACION_NO_SELECCIONADA);
                return false;
            }
            else if(!vm.solicitudCambio.descripcion) {
                toastr.info(DESCRIPCION_NO_AGREGADA);
                return false;
            }

            return true;
        }
    }
})();