(function () {
    'use strict';

    angular
        .module('app.medicos')
        .controller('ModificarMedicoController', ModificarMedicoController);

    ModificarMedicoController.$inject = ['especialidades', 'categoriasMedico', 'potenciales',
        'paises', 'zonas', 'semanas', 'giraCapital', 'sectores', 'medico', 'dias', 'medicamentos', '$state', 'toastr', 'medicosService', '$stateParams',
        'ERROR_GENERICO', 'MEDICO_MODIFICADO', 'DATOS_NO_MODIFICADOS', 'catalogosService'];


    function ModificarMedicoController(especialidades, categoriasMedico, potenciales,
                                       paises, zonas, semanas, giraCapital, sectores, medico, dias, medicamentos, $state, toastr, medicosService, $stateParams, ERROR_GENERICO, MEDICO_MODIFICADO,
                                       DATOS_NO_MODIFICADOS, catalogosService) {

        var vm = this;

        vm.data = {};
        var dialog;
        var medicoId = $stateParams.medicoId;
        var accionSolicitud = 0;
        vm.especialidades = especialidades;
        vm.categoriasMedico = categoriasMedico;
        vm.potenciales = potenciales;
        vm.paises = paises;
        vm.zonas = zonas;
        vm.semanas = semanas;
        vm.giraCapital = giraCapital.data;
        vm.sectores = sectores;
        vm.medico = medico;
        vm.medicamentos = medicamentos;
        vm.estaEditando = true;
        vm.vista = 2;
        vm.horarios = [{"horarioNombre": "AM"}, {"horarioNombre": "PM"}];
        vm.dias = dias;
        vm.verList = verList;
        vm.agregarMedicamento = agregarMedicamento;
        vm.eliminarMedicamento = eliminarMedicamento;
        vm.modificarMedico = modificarMedico;
        vm.getDepartamentos = getDepartamentos;
        vm.getCiudades = getCiudades;

        init();


        function verList() {
            $state.go('index.medicos.listar');
        }

        function agregarMedicamento() {
            var exists = vm.medico.medicamentos.find(function (item) {
                return angular.equals(vm.data.medicamentoId, item);
            });

            if (!exists) {
                var medicamento = {};
                medicamento.medicamentoId = vm.data.medicamento.medicamentoId;
                medicamento.nombre = vm.data.medicamento.medicamentoNombre;
                vm.medico.medicamentos.push(medicamento);
            }
        }

        function getDepartamentos(paisId) {
            catalogosService.getDepartamentos(paisId).then(function (result) {
                vm.departamentos = result;
            }, function (error) {
                toastr.error(error);
            });
        };

        function getCiudades(departamentoId) {
            catalogosService.getCiudades(departamentoId).then(function (result) {
                vm.ciudades = result;
            }, function (error) {
                toastr.error(error);
            });
        };

        function modificarMedico() {

            if (vm.giraCapital.giraCapitalId == 1) {
                vm.medico.gira = true;
                vm.medico.capital = false;
            } else {
                vm.medico.gira = false;
                vm.medico.capital = true;
            }

            var diff = {};
            for (var key in vm.medico) {
                if (!angular.equals(vm.medico[key], vm.edited[key]))
                    diff[key] = vm.medico[key];
            }

            var medicoSectorArray = [];
            if (diff.hasOwnProperty('medicoConsultorio')) {
                vm.edited.medicoConsultorio.forEach(function (item) {
                    var medicoSectorDiff = {};
                    vm.medico.medicoConsultorio.forEach(function (innerItem) {
                        if (item.medicoSectorId == innerItem.medicoSectorId) {
                            if (!angular.equals(item, innerItem)) {
                                for (var key in innerItem) {
                                    if (!angular.equals(innerItem[key], item[key])) {
                                        medicoSectorDiff[key] = innerItem[key];
                                        medicoSectorDiff.medicoSectorId = innerItem.medicoSectorId;
                                    }
                                }
                                medicoSectorArray.push(medicoSectorDiff);
                            }
                        }
                    });
                });
            }

            if (isEmpty(diff)) {
                toastr.info(DATOS_NO_MODIFICADOS);
                return;
            }

            eliminarEspaciosEnBlancoDeDatosMedico(vm.medico);

            medicosService.setModificarMedico(vm.medico).then(function (response) {
                if (response) {
                    toastr.success(MEDICO_MODIFICADO);
                    $state.go('index.medicos.detalle', {medicoId: medicoId});
                }
            }, function (error) {
                if (error.data.message)
                    toastr.error(error.data.message);
                else
                    toastr.error(ERROR_GENERICO);
            });
        }

        function eliminarEspaciosEnBlancoDeDatosMedico(medico) {

            for (var k in medico)
            {
                if (medico.hasOwnProperty(k))
                {
                    if (k === 'medicoSector')
                    {
                        for(var i = 0; i < vm.medico[k].length; i++)
                        {
                            for (var msk in medico[k][i])
                            {
                                if (medico[k][i].hasOwnProperty(msk))
                                {
                                    if (!angular.isObject(medico[k][i][msk]))
                                    {
                                        if (typeof medico[k][i][msk] === "string")
                                        {
                                            if (medico[k][i][msk].trim() === "")
                                            {
                                                medico[k][i][msk] = null;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (!angular.isObject(medico[k]))
                    {
                        if (typeof medico[k] === "string")
                        {
                            if (medico[k].trim() === "")
                            {
                                medico[k] = null;
                            }
                        }
                    }
                }
            }
        }

        function isEmpty(object) {
            return angular.equals({}, object);
        }

        function eliminarMedicamento(medicamentoId) {
            vm.medico.medicamentos = vm.medico.medicamentos.filter(function (item) {
                return item.medicamentoId != medicamentoId;
            });
        }

        function init() {
            vm.giraCapital.giraCapitalId = vm.medico.capital ? 2 : 1;
            vm.edited = angular.copy(vm.medico);
            getDepartamentos(medico.medicoSector[0].ciudad.departamento.pais.paisId);
            getCiudades(medico.medicoSector[0].ciudad.departamento.departamentoId);
        }
    }
})();