(function () {
    'use strict';
    
    angular
        .module('app.citas')
        .controller('ProgramarVisitasController', ProgramarVisitasController);

    ProgramarVisitasController.$inject = ['$state', 'usuariosService', 'catalogosService', 'medicos', 'uiGridConstants', 'medicosService', 'citasService',
        'instituciones','NgTableParams','ERROR_GENERICO', 'VISITAS_PROGRAMADAS_EXITOSAMENTE', 'ERROR_PROGRAMAR_VISITAS', 'toastr',
        'ERROR_CATEGORIA_VISITAS', 'ERROR_HORA_VISITAS', 'localStorageService', 'visitasGuardadas', 'VISITAS_GUARDADAS_EXITOSAMENTE', 'NO_HAY_VISITAS_PARA_GUARDAR'];

    function ProgramarVisitasController($state, usuariosService, catalogosService, medicos, uiGridConstants, medicosService, citasService, instituciones,
                                        NgTableParams, ERROR_GENERICO, VISITAS_PROGRAMADAS_EXITOSAMENTE, ERROR_PROGRAMAR_VISITAS, toastr, ERROR_CATEGORIA_VISITAS,
                                        ERROR_HORA_VISITAS, localStorageService, visitasGuardadas, VISITAS_GUARDADAS_EXITOSAMENTE, NO_HAY_VISITAS_PARA_GUARDAR) {
         var vm = this;

         vm.visitas = [];
         vm.visitasProgramadas = [];
         vm.fechaProgramacion = new Date();
         vm.idMedicoSeleccionado = null;
         vm.idInstitucionSeleccionada = null;
         vm.deshabilitarProgress = true;
        vm.horas = ('00,01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23').split(',').map(function (hora) {
            return { valor : hora};
        });
        vm.hora = -1;
        vm.minutos = ('00,15,30,45').split(',').map(function (minuto) {
            return {valor : minuto};
        });
        vm.minuto = -1;
         var consultorio = {};
         var nombre;
         var direccion;
         vm.tablaMedicos = new NgTableParams({count: 10}, {counts:[], paginationMaxBlocks: 7, dataset: medicos});
         vm.tablaInstituciones = new NgTableParams({count: 10}, {counts: [], paginationMaxBlocks: 7, dataset: instituciones});
        vm.tablaErrores = new NgTableParams({count: 5}, {counts: [], paginationMaxBlocks: 7, dataset: []});

         vm.seleccionarMedico = seleccionarMedico;
         vm.agregar = agregar;
         vm.removerVisita = removerVisita;
         vm.diasHabilesPredicate = diasHabilesPredicate;
         vm.seleccionarInstitucion = seleccionarInstitucion;
        vm.programar = programar;
         vm.guardar = guardar;
         vm.diaSeleccionado = diaSeleccionado;
         vm.cerrarMarkErrores = cerrarMarkErrores;
        cargarVistiasGuardadas();
         diaSeleccionado(1);

         function cerrarMarkErrores() {
             vm.mostrarErrores = false;
         }
         
         function diaSeleccionado(dia) {
             var fecha = new Date();
             fecha.setHours(0, 0, 0,0);
             /*
             * Si es viernes debe seleccionar la fecha del siguiente viernes,
             * porque esta programando las visitas para la proxima semana
              */
             if (fecha.getDay() === 5)
                 fecha.setDate(fecha.getDate() + (7 - fecha.getDay()) % 7 + dia);
             else
                fecha.setDate(fecha.getDate() + ((7 - fecha.getDay()) % 7 + dia) % 7);
             vm.fechaProgramacion = fecha;
         }
        function guardar() {
            if (vm.visitas.length > 0 ) {
                localStorageService.set('visitas', vm.visitas);
                toastr.success(VISITAS_GUARDADAS_EXITOSAMENTE);
            }
            else {
                toastr.info(NO_HAY_VISITAS_PARA_GUARDAR);
            }
        }

        function cargarVistiasGuardadas() {
            if (visitasGuardadas != null )
            {
                if(visitasGuardadas.length > 0) {
                    vm.visitas = visitasGuardadas;
                }
            }
        }
         function programar() {
             vm.btnProgramarDisabled = true;
             vm.deshabilitarProgress = false;
             citasService.programarVisitas(vm.visitas).then(function (response) {
                 toastr.success(VISITAS_PROGRAMADAS_EXITOSAMENTE);
                 vm.btnProgramarDisabled = false;
                 vm.deshabilitarProgress = true;
                 localStorageService.remove('visitas');
             }, function (error) {
                 vm.mostrarErrores = true;
                 toastr.error(ERROR_PROGRAMAR_VISITAS);
                 if (error.hasOwnProperty('data')) {
                     if (error.status === 409) {
                         vm.mensajeError = error.data.message;
                         vm.errores = error.data.data;
                         if (angular.isObject(vm.errores[0])) {
                             vm.tablaErrores.settings({dataset: error.data.data});
                             vm.tablaErrores.reload();
                         }
                     }
                     if (error.status === 400) {
                         vm.mensajeError = error.data.message;
                         vm.errores = [];
                     }
                 }
                 vm.btnProgramarDisabled = false;
                 vm.deshabilitarProgress = true;
             });
         }

         function diasHabilesPredicate(fecha) {
             var dia = fecha.getDay();
             return dia >=1 && dia <=5;
         }

         function removerVisita(medicoId, institucionId) {
            vm.visitas = vm.visitas.filter(function(v) {
                if (v.medicoId == null)
                    return v.institucionId !== institucionId;
                if (v.institucionId == null)
                    return v.medicoId !== medicoId;
                return true;
            });
         }

        function seleccionarMedico(medico) {
             vm.idMedicoSeleccionado = medico.medicoId;
             nombre = medico.nombre;
             seleccionarConsultorio(medico.medicoId);
         }

         function seleccionarInstitucion(institucion) {
            vm.idInstitucionSeleccionada = institucion.institucionId;
            nombre = institucion.nombre;
            direccion = institucion.direccion;
            consultorio = {};
            agregar();
         }

        function addHoursToDate(date, hours) {
            return new Date(new Date(date).setHours(hours));
        }

        function addMinutesToDate(date, minutes) {
            return new Date(new Date(date).setMinutes(minutes));
        }

         function seleccionarConsultorio(medicoId) {
            medicosService.getConsultorios(medicoId).then(function (response) {
                if (response.length > 1) {
                    //TODO mostrar dialogo para seleccionar consultorio feature se implementara en release 1.0.0.1
                }
                else {
                    consultorio = response[0];
                    direccion = consultorio.direccion;
                    agregar();
                }
             });
         }

         function agregar() {
            if (vm.hora === -1 || vm.minuto === -1)
             {
                 toastr.error(ERROR_HORA_VISITAS);
             }
             else {
                var fechaProgramacionHoras = addHoursToDate(vm.fechaProgramacion, vm.hora);
                var fechaProgramacionMinutos = addMinutesToDate(fechaProgramacionHoras, vm.minuto);
                agregarVisita(vm.idMedicoSeleccionado, consultorio.medicoSectorId, fechaProgramacionMinutos, vm.idInstitucionSeleccionada, nombre, direccion);
            }
         }

         function agregarVisita(medicoId, medicoSectorId, fechaProgramacion, institucionId, nombre, direccion) {

             if (medicoId == null && institucionId == null)
             {
                 return;
             }

             var visita = {};
             visita.medicoId = medicoId;
             visita.medicoSectorId = medicoSectorId;
             visita.fechaProgramacion = moment(fechaProgramacion).format('YYYY-MM-DDTHH:mm:ss');
             visita.institucionId = institucionId;
             visita.nombre = nombre;
             visita.direccion = direccion;
             visita.numeroDia = fechaProgramacion.getDay();
             var contains = containsObject(visita, vm.visitas);
             if(!contains) {
                 vm.visitas.push(visita);
                 vm.idInstitucionSeleccionada = null;
                 vm.idMedicoSeleccionado = null;
             }
         }
         
         function containsObject(obj, list) {
             var i;
             for (i = 0; i < list.length; i++) {
                 if (obj.institucionId !== null)
                 {
                     if (obj.institucionId === list[i].institucionId)
                     {
                         return true;
                     }
                 }
                 if (obj.medicoId !== null) {
                     if (obj.medicoId === list[i].medicoId) {
                         return true;
                     }
                 }
             }

             return false;
         }
    }
})();