(function () {
    'use strict';

    angular.module('app.usuarios')
        .controller('AgregarUsuariosController', AgregarUsuariosController);

    AgregarUsuariosController.$inject = ['$state', 'toastr', 'usuariosService', 'roles', 'sectores', 'IMAGEN_INVALIDA', 'USUARIO_CREADO', 'ERROR_GENERICO'];

    function AgregarUsuariosController($state, toastr, usuariosService, roles, sectores, IMAGEN_INVALIDA, USUARIO_CREADO, ERROR_GENERICO) {
        var vm = this;

        vm.request =  {};
        vm.image = {};
        vm.usuario = {};
        vm.usuario.sectores = [];
        vm.roles = roles;
        vm.sectores = sectores;
        vm.agregarSector = agregarSector;
        vm.eliminarSectore = eliminarSector;
        vm.guardar = guardar;
        vm.fileSelect = fileSelect;
        vm.borrarImg = borrarImg;

        function eliminarSector() {
            vm.usuario.sectores = vm.usuario.sectores.filter(function (item) {
                return item.id != sector.id;
            });
        }
        
        function agregarSector() {
            if (vm.usuario.sector) {
                var exists = vm.usuario.sectores.find(function (item) {
                    return item.id == vm.usuario.sector.id;
                });
                if (!exists) {
                    vm.usuario.sectores.push(vm.usuario.sector);
                }
            }
        }

        function guardar() {

            var userimage = vm.image.data;

            if (userimage) {
                if (parseInt(userimage.filesize) > 50000) {
                    toastr.info(IMAGEN_INVALIDA);
                    return;
                }

                vm.request.base64Img = 'data:' + userimage.filetype + ';base64,' + userimage.base64;
            }

            vm.request.usuario = vm.usuario;

            usuariosService.setUsuario(vm.request).then(function (response) {
                if (response) {
                    toastr.success(USUARIO_CREADO);
                    vm.usuario = null
                    vm.usuario.sectores = [];
                    $state.go('index.usuarios.list');
                }
            }, function (error) {
                if (error.data.message)
                    toastr.error(error.data.message);
                else
                    toastr.error(ERROR_GENERICO);
            });
        }

        //Funciones
         function fileSelect() {
            if (vm.image.data) {
                var userimage = vm.image.data;
                vm.request.base64Img = 'data:' + userimage.filetype + ';base64,' + userimage.base64;
            }
        }
        function borrarImg() {
            vm.request.base64Img = null;
            vm.image = {};
        }
    }
})();