// JavaScript source code
var citaDetalleMdl = angular.module('citaDetalleMdl', ['app.citasService', 'toastr']);


citaDetalleMdl.controller('citaFinalizadaCtrl', ['$scope', 'citasService', 'toastr',
    function ($scope, citasService, toastr) {

        citasService.getCita($scope.citaFinalizada.citaId).then(function (response) {

            $scope.totalVenta = 0;

            for (var key in response) {
                $scope.citaFinalizada[key] = response[key];
            }

            if (response.medicamentos) {
                response.medicamentos.forEach(function (item) {
                    if (item.subTotalVenta != null) {
                        $scope.totalVenta += parseInt(item.subTotalVenta);
                    }
                });
            }

        }, function (error) {
            console.log(error);
        });

        $scope.citaFinalizada.aceptar = function () {

            $scope.closeThisDialog();
        }

    }]);