var finalizarCitaMdl = angular.module('finalizarCitaMdl', ['app.catalogosService', 'app.citasService','toastr']);

finalizarCitaMdl.controller('finalizarCitaCtrl', ['$scope', 'catalogosService', 'citasService', 'toastr'
    , function ($scope, catalogosService, citasService, toastr) {

    $scope.data = {};
    $scope.data.medicamentoId = 1;
    $scope.data.citaMedicamentos = [];
    $scope.totalVenta = 0;
    $scope.data.userId = -1;
   
    var medicamentosTmp = [];

    catalogosService.getMedicamentos().then(function(response){
        $scope.medicamentos = response;
    }, function (error) {
        console.log(error);
    });

    catalogosService.getAcompaniantes().then(function (response) {
        $scope.usuarios = response;
    }, function (error) {
        console.log(error);
    });

    $scope.agregarMedicamento = function () {
      
        if (!$scope.data.cantidadMedicamento) {
            toastr.error('DEBE ESPECIFICAR LA CANTIDAD DE MEDICAMENTOS ENTREGADOS');
            return;
        }

        $scope.agregoMedicamento = true;

        var medicamento = $scope.medicamentos.find(function (item) {
            return item.medicamentoId === $scope.data.medicamentoId;
        })
        
        if ($scope.data.citaMedicamentos.indexOf(medicamento) == -1) {
            medicamento.cantidadMedicamento = $scope.data.cantidadMedicamento;
            if ($scope.data.subTotalVenta) {
                medicamento.subTotalVenta = $scope.data.subTotalVenta;
                $scope.totalVenta += parseInt(medicamento.subTotalVenta);
            }
            else
                medicamento.subTotalVenta = null;
            $scope.data.citaMedicamentos.push(medicamento);
        }

        $scope.data.cantidadMedicamento = null;
        $scope.data.subTotalVenta = null;
    }

    $scope.terminarCita = function () {
        var tcData = {};
        for (var key in $scope.data) {

            if (key !== 'medicamentoId') {
                if (key !== 'cantidadMedicamento') {
                    tcData[key] = $scope.data[key];
                }
            }
        }
  
        if ($scope.data.userId > 0 ) {
            tcData.userVisitaConjuntoId = $scope.data.userId;
        }

        citasService.setTerminarCita($scope.finalizarCita.citaId, tcData).then(function (response) {
            if (response) {
                toastr.success('VISITA FINALIZADA CORRECTAMENTE');

                var nuevaCita = {
                    citaId: $scope.finalizarCita.citaId,
                    title: $scope.finalizarCita.title,
                    estadoCita: 3,
                    startsAt: new Date(),
                    color: { primary: '#00FF00', secondary: '#3bff3b' },
                    editable: false,
                    deletable: false,
                    draggable: true,
                    resizable: true,
                    allDay: true,
                }

                var cita = $scope.events.filter(function (item) {
                    return item.citaId == $scope.finalizarCita.citaId;
                });

                var index = $scope.events.indexOf(cita[0]);
                if (index !== -1) {
                    $scope.events[index] = nuevaCita;
                }

                $scope.closeThisDialog();
            }
        }, function (error) {
            if (error.data.message) {
                toastr.error(error.data.message);
            }
            else
                toastr.error('HA OCURRIDO UN ERROR');
        });
    }

    $scope.cancelar = function () {
        $scope.closeThisDialog();
    }
}]);