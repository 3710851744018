(function () {
    'use strict';

    angular
        .module('app.dashboard')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['toastr','dashboardService', 'authService', 'ciclos', 'semanas', 'cicloActual', 'usuarios', 'auth', 'authFactory','anios'];


    function DashboardController(toastr, dashboardService, authService, ciclos, semanas, cicloActual, usuarios, auth, authFactory,anios) {

        var vm = this;

        vm.isOpen = false;
        vm.isFechaFinOpen = false;
        vm.isAdmin = authFactory.estaEnRol('ROLE_ADMIN') || authFactory.estaEnRol("ROLE_SUPERVISOR");
        vm.usuarios = usuarios;
        vm.anios = anios;
        var anio = 0;
        var totalMedicos = 0;
        // Iniciamos el chart (gauge) de medicos
        vm.chartMedicos = {};
        vm.chartMedicos.type = "Gauge";
        vm.chartMedicos.options = {
            width: 400,
            height: 180,
            redFrom: 50,
            redTo: 75,
            yellowFrom: 75,
            yellowTo: 90,
            greenFrom: 90,
            greenTo: 100,
            minorTicks: 5
        };
        vm.chartMedicos.data = [
            ['Label', 'Value'],
            ['', 0]
        ];
        // Iniciamos el chart(gauge) de instituciones
        vm.chartInstituciones = {};
        vm.chartInstituciones = angular.copy(vm.chartMedicos);
        //Iniciamos el chart(column) para el detalle de visitas medicas
        vm.chartVisitasMedicas = {};
        vm.chartVisitasMedicas.type = "ColumnChart";
        vm.chartVisitasMedicas.data = {};
        vm.chartVisitasMedicas.data.cols = [
                { id: "t", label: "Especialidad", type: "string" },
                { id: "s", label: "Visitas", type: "number" }];

        vm.chartVisitasMedicas.options = {
            'title': 'Detalles de visitas medicas por especialidad'
        };
        //Iniciamos el chart(column) para el detalle de visitas instituciones
        vm.chartVisitasInstituciones = {};
        vm.chartVisitasInstituciones.type = "ColumnChart";
        vm.chartVisitasInstituciones.data = {};
        vm.chartVisitasInstituciones.data.cols = [
                { id: "t", label: "Tipo Institucion", type: "string" },
                { id: "s", label: "Visitas", type: "number" }];

        vm.chartVisitasInstituciones.options = {
            'title': 'Detalles de visitas medicas por institucion'
        };
        //Iniciamos el chart(table) para el resumen de visitas medicas
        vm.tablaResumenVM = {};
        vm.tablaResumenVM.type = "Table",
        vm.tablaResumenVM.data = {};
        vm.tablaResumenVM.displayed = false;
        vm.tablaResumenVM.formatters = {};
        
        // Iniciamos el chart column para las muestras medicas
        vm.chartMuestrasMedicas = {};
        vm.chartMuestrasMedicas.type = "ColumnChart";
        vm.chartMuestrasMedicas.data = {};
        vm.chartMuestrasMedicas.data.cols = [
                { id: "t", label: "Producto", type: "string" },
                { id: "s", label: "Cantidad", type: "number" }];

        vm.chartMuestrasMedicas.options = {
            'title': 'Detalles de muestras medicas entregadas'
        };
        // Iniciamos el chart column para los productos comerciales
        vm.chartComerciales = {};
        vm.chartComerciales.type = "ColumnChart";
        vm.chartComerciales.displayed = false;
        vm.chartComerciales.data = {};
        vm.chartComerciales.data.cols = [
                { id: "p", label: "Producto", type: "string" },
                { id: "c", label: "Cantidad", type: "number" },
                { id: "t", label: "Total", type: "number" }];

        vm.chartComerciales.options = {
            'title': 'Detalles de productos vendidos',
            "bar": { "groupWidth": '25%' }
        };
        //Filtros
        vm.filter = {};
        //Data
        vm.data = {};

        vm.data.totalMedicos = 0;
        vm.data.totalInstituciones = 0;
        vm.data.totalMedicamentos = 0;
        vm.data.universoMedico = 0;
        vm.data.totalVentas = 0;
        vm.data.universoInstituciones = 0;
               
        vm.ciclos = ciclos;       
        vm.semanas = semanas;
        vm.verEstadisticas = verEstadisticas;

        init();

        function init() {
            if (cicloActual != null) {
                anio = cicloActual.anioId;
                vm.filter.cicloId = cicloActual.cicloId;
                vm.filter.semanaId = cicloActual.semanaId;
            }
        }

        function verEstadisticas() {
            var username = null;
            var request = {};
            request.cicloId = vm.filter.cicloId;
            request.semanaId = vm.filter.semanaId;
            request.fechaInicio = vm.filter.fechaInicio;
            request.fechaFin = vm.filter.fechaFin;
            request.anioId = vm.filter.anioId;
            request.username = vm.filter.username;

            if (!vm.isAdmin)
                request.username = authService.getLoginData().username;

         
            dashboardService.getEstadisticas(username, request).then(function (response) {
                console.log(response);
                //instituciones
                vm.chartVisitasInstituciones.data.rows = [];
                vm.chartInstituciones.data[1] = ['', response.instituciones.porcentajeVisitados];
                vm.data.totalInstituciones = response.instituciones.totalVisitados;
                vm.data.universoInstituciones = response.instituciones.universo;
                //medicamentos
                vm.chartMuestrasMedicas.data.rows = [];
                vm.chartComerciales.data.rows = [];
                vm.data.totalMuestrasMedicas = response.medicamentos.contMuestras;
                vm.data.totalProductosComerciales = response.medicamentos.contVendidos;
                vm.data.totalMedicamentos = response.medicamentos.totalProductos;
                vm.data.totalVentas = response.medicamentos.totalVentas;
                //medicos
                vm.chartVisitasMedicas.data.rows = [];
                vm.tablaResumenVM.data.rows = [];
                vm.chartMedicos.data[1] = ['', response.medicos.porcentajeVisitados];
                vm.data.totalMedicos = response.medicos.totalVisitados;
                vm.data.universoMedico = response.medicos.universo;
                vm.tablaResumenVM.data.cols = [
                    {
                        id: "especialidad",
                        label: "ESPECIALIDAD",
                        type: "string"
                    }
                ];

                response.medicos.tablaResumen.columnas.forEach(function (item) {
                    var col = {};
                    col.id = item;
                    col.label = item;
                    col.type = "number";
                    vm.tablaResumenVM.data.cols.push(col);
                });

                vm.tablaResumenVM.data.cols.push({
                    id: "total",
                    label: "Total",
                    type: "number"
                });

                vm.tablaResumenVM.data.rows = response.medicos.tablaResumen.filas;
                vm.chartVisitasMedicas.data.rows = response.medicos.columnChartDataRows;
                vm.chartVisitasInstituciones.data.rows = response.instituciones.columnChartDataRows;
                vm.chartMuestrasMedicas.data.rows = response.medicamentos.muestrasColumnChartDataRows;
                vm.chartComerciales.data.rows = response.medicamentos.vendidosColumnChartDataRows;

            }, function (error) {
                if (error.data.message)
                    toastr.error(error.data.message);
            }); // fin getEstadisticasCitasMedicos


            //// estadisticas medicamentos
            //dashboardService.getEstadisticasMedicamentos(username, request).then(function (response) {
            //    vm.chartMuestrasMedicas.data.rows = [];
            //    vm.chartComerciales.data.rows = [];
            //    vm.data.totalMuestrasMedicas = response.totalMuestrasMedicas;
            //    vm.data.totalProductosComerciales = response.totalComerciales;
            //    vm.data.totalMedicamentos = response.totalProductos;
            //    vm.data.totalVentas = response.totalVentas;

            //    // crea las filas para el chart de muestras medicas
            //    response.muestrasMedicas.forEach(function (item) {
            //        var dataRow = {};
            //        dataRow.c = [];
            //        var cell00 = {};
            //        var cell01 = {};
            //        cell00.v = item.nombreMedicamento;
            //        cell01.v = item.cantidadEntregada;
            //        dataRow.c.push(cell00);
            //        dataRow.c.push(cell01);
            //        vm.chartMuestrasMedicas.data.rows.push(dataRow);
            //    });

            //    // crea las filas para el chart de productos comerciales
            //    response.productosComerciales.forEach(function (item) {
            //        var dataRow = {};
            //        dataRow.c = [];
            //        var cell00 = {};
            //        var cell01 = {};
            //        var cell02 = {};
            //        cell00.v = item.nombreMedicamento;
            //        cell01.v = item.cantidadEntregada;
            //        cell02.v = item.subTotalVenta;
            //        dataRow.c.push(cell00);
            //        dataRow.c.push(cell01);
            //        dataRow.c.push(cell02);
            //        vm.chartComerciales.data.rows.push(dataRow);
            //    });

            //}, function (error) {
            //    if (error.data.message)
            //        toastr.error(error.data.message);
            //});

        } //verEstadisticas
    }
})();