angular.module( 'app.authService', [
  'LocalStorageModule',
  'app.utilsService'
])

.factory( 'authService', ['$http', '$q', 'localStorageService', 'utilsService', 'appSettings', '$httpParamSerializer',  
                 function ($http,   $q,   localStorageService,   utilsService,   appSettings,   $httpParamSerializer) {

  var auth = {};

  
  auth.saveLoginData = function ( loginData ) {
   
    localStorageService.set( 'loginData', loginData);
  };

  auth.getLoginData = function () {
    return localStorageService.get( 'loginData' );
  };

  auth.login = function(data1) {  
      
      data1.grant_type = 'password';
      var req = {
            method: 'POST',
            url: appSettings.restOauth,
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=utf-8"
            },
            data: $httpParamSerializer(data1)
        }
      var promise = $http(req).then(function (response) {
          localStorageService.set('loginData', response.data);
          return response.data;
      });
      return promise;
   }

 
  auth.logOut = function () {
    localStorageService.remove( 'loginData' );
  };

  auth.isLoggedIn = function () {
    return auth.getLoginData() ? true : false;
  };

  auth.loginPermission = function ( data ) {
    var deferred = $q.defer();
    $http.post( appSettings.restApiServiceBaseUri + 'Sesion/' +  data.username +'/'+ data.password +'/login' ).success( function ( res ) {

      var permiso = utilsService.findByField( res.permisos, 'ID_DESPACHO', auth.getLoginData().id_despacho );

      if ( !permiso ) {
        deferred.reject( "No tiene permiso para este depacho." );
      } else {
        deferred.resolve( permiso );
      }

    }).error( function ( error ) {
      deferred.reject( error );
    });
    return deferred.promise;
  };

  auth.userPermission = function ( idUsuario, opcion ) {
    var deferred = $q.defer();
    $http.get( appSettings.restApiServiceBaseUri + 'PermisoOpcion/' + idUsuario + '/' + opcion ).success( function ( data ) {
      deferred.resolve( data );
    }).error( function ( error ) {
      deferred.reject( error );
    });
    return deferred.promise;
  };

  return auth;

}]);
