(function () {
    'use strict';

    angular
        .module('app.usuarios', ['ui.router',
            'toastr',
            'app.usuariosService',
            'app.authService',
            'naif.base64'])
        .constant('USUARIO_MODIFICADO', 'Usuario modificado con \u00E9xito')
        .constant('IMAGEN_INVALIDA', 'No es posible utilizar esta imagen')
        .constant('USUARIO_CREADO', 'Usuario creado exitosamente');
})();