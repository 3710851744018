angular.module( 'app.catalogosService', [
  'LocalStorageModule',
  'app.authService',
  'app.utilsService'
])

.factory( 'catalogosService', ['$http', '$q', 'appSettings',
                      function ($http,   $q,   appSettings) {
  return {
    getPotenciales: function() {

        return $http.get(appSettings.restApiServiceBaseUri + 'potenciales').then(function (response) {
            if (response.status === 200)
                return response.data;
        });
      },
    getPaises: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'paises' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
    getDepartamentos: function(paisId) {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'paises/' + paisId + '/departamentos' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
    getCiudades: function(departamentoId) {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'departamentos/' + departamentoId + '/ciudades' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
    getZonas: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'zonas' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
    getSectores: function(ciudadId) {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'ciudades/' + ciudadId + '/sectores' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
      },
    getCalendario: function(username,mesId,anioId) {

        return $http.get(appSettings.restApiServiceBaseUri + 'citas/username/' + username + '/mes/' + mesId + '/anio/' + anioId).then(function (response) {
            return response.data;
        }, function (error){
            if (error.status === 401) {
                throw 'Su sesi\xF3n ha expirado.';
            }
            return error;
        });
      },
    getUsuarios: function() {

        return $http.get(appSettings.restApiServiceBaseUri + 'usuarios').then(function (response) {
            if (response.status === 200)
                return response.data;
            return response;
        });
    },
    getAcompaniantes: function(){
        return $http.get(appSettings.restApiServiceBaseUri + 'usuarios/acompaniantes').then(function (response) {
            if (response.status === 200)
                return response.data;
            return response;
        });
    },
    getGiraCapital: function() {
      var deferred = $q.defer();
      deferred.resolve({"status":"OK","message":"success","data":[{"giraCapitalId":1,"giraCapitalNombre":"Gira"},{"giraCapitalId":2,"giraCapitalNombre":"Capital"}]});
      return deferred.promise;
    },
    getTiposInstitucion: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'tiposInstitucion' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
    },
    getCadenasFarmacia: function() {
        var deferred = $q.defer();
        $http.get( appSettings.restApiServiceBaseUri + 'cadenasFarmacia' ).success( function ( data ) {
          deferred.resolve( data );
        }).error( function ( error ) {
          deferred.reject( error );
        });
        return deferred.promise;
    },
    getEspecialidades: function() {
      var deferred = $q.defer();
      $http.get( appSettings.restApiServiceBaseUri + 'especialidades' ).success( function ( data ) {
        deferred.resolve( data );
      }).error( function ( error ) {
        deferred.reject( error );
      });
      return deferred.promise;
    },
    getAnios: function() {
      var deferred = $q.defer();
      $http.get( appSettings.restApiServiceBaseUri + 'anios' ).success( function ( data ) {
        deferred.resolve( data );
      }).error( function ( error ) {
        deferred.reject( error );
      });
      return deferred.promise;
    },
    getCiclos: function() {
        return $http.get(appSettings.restApiServiceBaseUri + 'ciclos').then(function (response) {
          if (response.status === 200)
              return response.data;
          return response;
      });
    },
    getSemanas: function() {
    return $http.get(appSettings.restApiServiceBaseUri + 'semanas').then(function (response) {
          if (response.status === 200)
              return response.data;
          return response;
      });
    },
    getDias: function(){
        var url = appSettings.restApiServiceBaseUri + 'semanas/dias';
        return $http.get(url).then(function (response) {
            if (response.status === 200)
                return response.data;
            return response;
        });
    },
    getMedicamentos: function() {
      var deferred = $q.defer();
      $http.get( appSettings.restApiServiceBaseUri + 'medicamentos' ).success( function ( data ) {
        deferred.resolve( data );
      }).error( function ( error ) {
        deferred.reject( error );
      });
      return deferred.promise;
    },
    getCitasMedico: function (medicoId, page, size) {
        var url = appSettings.restApiServiceBaseUri + 'medicos/' + medicoId + '/citas?page=' + page + '&size=' + size;
        return $http.get(url).then(function (response) {
            if (response.status === 200)
                return response.data;
            return response;
        });
    },
    getCitasInstitucion: function(institucionId, page, size){
        var url = appSettings.restApiServiceBaseUri + 'instituciones/' + institucionId + '/citas?page=' + page + '&size=' + size;
        return $http.get(url).then(function (response) {
            if (response.status === 200)
                return response.data;
            return response;
        });
    },
    getCicloActual: function () {
        var url = appSettings.restApiServiceBaseUri + 'ciclos/actual';
        return $http.get(url).then(function (response) {
            return response;
        });
    },
    getAllSectores: function () {
        var url = appSettings.restApiServiceBaseUri + 'sectores';
        return $http.get(url).then(function (response) {
            if (response.status === 200)
                return response.data;
        });
    },
    getSectoresPorUsername: function (username) {
        var url = appSettings.restApiServiceBaseUri + 'sectores/' + username;
        return $http.get(url).then(function (response) {
            if (response.status === 200)
                return response.data;
        });
    },
    getEstadosVisita: function () {
        var url = appSettings.restApiServiceBaseUri + 'estados';
        return $http.get(url).then(function (response) {
            if (response.status === 200)
                return response.data;
        });
    }
  }
}]);