(function(){

    'use strict';

    angular
        .module('app.instituciones')
        .controller('ListarInstitucionesController', ListarInstitucionesController);

    ListarInstitucionesController.$inject = ['$state', 'NgTableParams', 'instituciones', 'utilsService', 'auth', 'authFactory', 'usuarios', 'toastr', 'institucionesService'];

    function ListarInstitucionesController($state, NgTableParams, instituciones, utilsService, auth, authFactory, usuarios, toastr, institucionesService) {

        var vm = this;
        vm.tablaInstituciones = new NgTableParams({}, { dataset: instituciones });
        vm.mostrarDetalles = mostrarDetalles;
        vm.imprimirReporte = imprimirReporte;
        vm.esAdmin = authFactory.estaEnRol('ROLE_ADMIN');
        vm.esSupervisor = authFactory.estaEnRol('ROLE_SUPERVISOR');
        vm.usuarios = usuarios;
        vm.filtrarInstituciones = filtrarInstituciones;

        function filtrarInstituciones() {
            if(!vm.username)
            {
                toastr.info('Debe seleccionar al menos un filtro');
                return;
            }
            institucionesService.getInstitucionesPorUsuario(vm.username).then(function (response) {
                instituciones = response;
                vm.tablaInstituciones.settings({ dataset: instituciones });
                vm.tablaInstituciones.reload();
            }, function (error) {
                console.log(error);
            });
        }
        function mostrarDetalles(institucionId) {
            $state.go('index.instituciones.detalle', { institucionId: institucionId});
        }

       function imprimirReporte () {
            utilsService.reporteUniversos(instituciones, 'institucion');
        }
    }
})();