(function () {
    'use strict';

    angular
       .module('app.medicos', [
               'toastr',
               'app.medicosService',
               'app.catalogosService',
               'ngDialog',
               'ui.mask'])
        .filter('yesNo', yesNoFilter)
        .constant('MEDICO_ELIMINADO', 'M\u00E9dico dado de baja exitosamente')
        .constant('DESEA_ELIMINAR_MEDICO', '¿Esta seguro que desea dar de baja a este m\u00E9dico?')
        .constant('NO_HAY_CITAS_RANGO_FECHAS', 'No hay citas en las fechas especificadas')
        .constant('MEDICO_MODIFICADO', 'Datos modificados exitosamente')
        .constant('DATOS_NO_MODIFICADOS', 'No se han modificado datos');

    function yesNoFilter() {
        return function (input) {
            return input ? 'SI' : 'NO';
        }
    }

})();