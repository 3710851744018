angular.module('app.citasService', [
  'app.authService',
])

.factory('citasService', ['$http', '$q', 'appSettings', 'authService', function ( $http, $q, appSettings, authService) {

  return {
  	getTipoCita: function() {
      var deferred = $q.defer();
      deferred.resolve({"status":"OK","message":"success","data":[{"tipoCitaId":1,"tipoCitaNombre":"Medicos"},{"tipoCitaId":2,"tipoCitaNombre":"Instituciones"}]});
      return deferred.promise;
    },
    getSectorUsuario: function() {
      var deferred = $q.defer();
      var loginData = authService.getLoginData();
      $http.get( appSettings.restApiServiceBaseUri + 'usuarios/' + loginData.data.username + '/sectores ' ).success( function ( data ) {
        deferred.resolve( data );
      }).error( function ( error ) {
        deferred.reject( error );
      });
      return deferred.promise;
    },
    getMedicosSectorEspecialidad: function(sectorId,especialidadId) {
        return $http.get( appSettings.restApiServiceBaseUri + 'medicos/sector/' + sectorId + '/especialidad/' + especialidadId ).then( function ( response ) {
            if (response.status == 200)
                return response.data;
        });
    },
    getConsultorioMedicosSector: function(sectorId,medicoId) {
      var deferred = $q.defer();
      $http.get( appSettings.restApiServiceBaseUri + 'consultorios/sector/' + sectorId + '/medico/' + medicoId ).success( function ( data ) {
        deferred.resolve( data );
      }).error( function ( error ) {
        deferred.reject( error );
      });
      return deferred.promise;
    },
    getInstitucionSectorTipoInstitucion: function(sectorId,tipoInstitucionId) {
        return $http.get(appSettings.restApiServiceBaseUri + 'instituciones/sector/' + sectorId + '/tipoInstitucion/' + tipoInstitucionId).then(function (response) {
            if (response.status == 200)
                return response.data;
        });
    },
    programarVisitas: function (data) {
        var promise = $http.post(appSettings.restApiServiceBaseUri + 'citas/programar', data).then(function (response) {
            return response;
        });
        return promise;
    },
    setIniciarCita: function ( citaId ) {
      var deferred = $q.defer();
      $http.put( appSettings.restApiServiceBaseUri + 'citas/' + citaId + '/iniciar' ).success( function ( data, status ) {
        deferred.resolve( status );
      }).error( function ( error ) {
        deferred.reject( error );
      });
      return deferred.promise;
    },
    getCitasPreProgramadas: function(anioId,cicloId,semanaId) {
      
        var promise = $http.get(appSettings.restApiServiceBaseUri + 'citas/preprogramadas/anio/' + anioId + '/ciclo/' + cicloId + '/semana/' + semanaId).then(function (response) {
            if (response.status == 200) {
                return response.data;
            }
            else {
                return response;
            }
      });
      return promise;
    },
    setCancelarCita: function (citaId, motivoCancelacion) {
        var url = appSettings.restApiServiceBaseUri + 'citas/' + citaId + '/cancelar';
        var promise = $http.put(url, motivoCancelacion).then(function (response) {
            return response.status == 204;
        });
        return promise;
    },
    getDetalleCita: function (citaId) {
        var url = appSettings.restApiServiceBaseUri + 'citas/' + citaId + '/detalle';
        var promise = $http.get(url).then(function (response) {
            if (response.status == 200) {
                return response.data;
            }
            return response;
        });

        return promise;
    },
    setTerminarCita: function (citaId, data) {
        var url = appSettings.restApiServiceBaseUri + 'citas/' + citaId + '/terminar';
        var promise = $http.put(url, data).then(function (response) {
            return response.status == 204;
        });
        return promise;
    },
    getCita: function (citaId) {
        var url = appSettings.restApiServiceBaseUri + 'citas/' + citaId;
        var promise = $http.get(url).then(function (response) {
            if (response.status == 200) {
                return response.data;
            }
            return response;
        });

        return promise;
    },
    getProgramacionMedicos: function (username) {
        var url = appSettings.restApiServiceBaseUri + 'medicos/' + username + '/programacion';
        return $http.get(url).then(function (response) {
            if (response.status == 200)
                return response.data;
        });
    },
    setProgramacionMedicos: function (username, data) {
        var url = appSettings.restApiServiceBaseUri + 'medicos/' + username + '/modificar_programacion';

        return $http.put(url, data).then(function (response) {

            return response.status == 204;
        });
    },
    getProgrmacionInstituciones: function (username) {
        var url = appSettings.restApiServiceBaseUri + 'instituciones/' + username + '/programacion';
        return $http.get(url).then(function (response) {

            if (response.status == 200)
                return response.data;
        });
    },
    setProgramacionInstituciones: function (username, data) {
        var url = appSettings.restApiServiceBaseUri + 'instituciones/' + username + '/modificar_programacion';

        return $http.put(url, data).then(function (response) {

            return response.status == 204;
        });
    }
  }                                                                                              
}]);