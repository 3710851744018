(function () {
    'use strict';

    angular
        .module('app.solicitudes', [
            'ui.router',
            'toastr'
        ])
        .constant('SOLICITUD_APROBADA', 'La solicitud ha sido aprobada')
        .constant('SOLICITUD_RECHAZADA', 'La solicitud ha sido rechazada')
        .constant('SOLICITUDES_CREADAS', 'Las solicitudes han sido creadas exitosamente')
        .constant('MEDICO_INSTITUCION_NO_SELECCIONADO', 'Debe seleccionar un m\u00E9dico o farmacia')
        .constant('OPERACION_NO_SELECCIONADA', 'Debe seleccionar una operaci\u00F3n')
        .constant('DESCRIPCION_NO_AGREGADA', 'Debe agregar una descripci\u00F3n');
})();