(function () {
    'use strict';

    angular.
        module('app.citas')
        .config(config);

    function config($stateProvider, $mdDateLocaleProvider, $mdThemingProvider) {
        $stateProvider
            .state('index.citas', {
                url: 'visitas',
                abstract: true,
                template: '<ui-view/>'
            })
            .state('index.citas.programar', {
                url: '/programar',
                templateUrl: 'app/citas/citas.programar.tpl.html',
                controller: 'ProgramarVisitasController',
                controllerAs: 'vm',
                resolve: {
                    medicos: medicos,
                    instituciones: instituciones,
                    visitasGuardadas: visitasGuardadas
                }
            });

        $mdDateLocaleProvider.formatDate = function(date) {
            return moment(date).format('DD/MM/YYYY');
        };

        $mdThemingProvider.definePalette('dogma-theme', {
            '50': 'eff8f7',
            '100': 'd6eeea',
            '200': 'bbe3dc',
            '300': '9fd7ce',
            '400': '8bcfc4',
            '500': '76c6b9',
            '600': '6ec0b2',
            '700': '63b9aa',
            '800': '59b1a2',
            '900': '46a493',
            'A100': 'fcffff',
            'A200': 'c9fff5',
            'A400': '96ffeb',
            'A700': '7dffe6',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': [
                '50',
                '100',
                '200',
                '300',
                '400',
                '500',
                '600',
                '700',
                '800',
                '900',
                'A100',
                'A200',
                'A400',
                'A700'
            ],
            'contrastLightColors': []});

        $mdThemingProvider.theme('default')
            .primaryPalette('dogma-theme');
    }

    function medicos(medicosService) {
        return medicosService.listMedicos();
    }

    function instituciones(institucionesService) {
        return institucionesService.listInstituciones();
    }

    function visitasGuardadas(localStorageService) {
        return localStorageService.get('visitas');
    }
})();